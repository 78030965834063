import { useState, createContext, useContext } from "react";
import { AnketaContext } from "../context/ContextComponent";
import { Form, Button, Col } from "react-bootstrap";
import PredmetOdsjekIzvjestaj from "./PredmetOdsjekIzvjestaj";

const BirajNastavnikaPredmetIzvjestaj = (props) =>{
    const  {setOdsjekPredmetIzvjestaj, setPredmetkAnketa}  = useContext(AnketaContext);
    
    
    const handleSelectChange = (e)=>{
        const myArray = e.target.value.split(",");
        let a = []
        let b = []

        myArray.map((el)=>{
            let x = el.split(":")
            // console.log(x)
            if(x[0]=="predmetID"){
                a.push(x[1])
            } else if(x[0]=="Odsjek"){
                b.push(x[1])
            }else{
                b.push(x[0])
            }
        })
        setOdsjekPredmetIzvjestaj(b)
        setPredmetkAnketa(a)
        // console.log(a)
    }
    const data = props.data

        // returns a new object with the values at each key mapped using mapFn(value)
        const mappedObj = Object.keys(data).map((key) => {
            return {
              label: key,
              value: data[key]
            };
          });

       let list2 = data?.map((x)=>{
            return <option value={`predmetID:${x._id},Odsjek:${x?.odsjek?.map((y)=>y.nazivOdsjeka)}`}>{x.nazivPredmeta}</option>
        })

    
    return(
       
        <>
         <Form.Label className="fw-bold">Odaberi predmet:</Form.Label>
    <Form.Control as="select" 
    onChange={handleSelectChange}
    >
       <option value="none" selected disabled hidden> </option> 
       {list2}
    </Form.Control>
       {/* <PredmetOdsjekIzvjestaj/> */}
        </>
    )
}

export default BirajNastavnikaPredmetIzvjestaj;