// import "./newProduct.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {useState, useEffect} from 'react'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Typeahead } from 'react-bootstrap-typeahead'; 
import { Form, Button, Col, InputGroup } from "react-bootstrap";
import axios from 'axios';
import Url from '../api/defUrl'
import { css } from '@emotion/react';
import { DotLoader } from 'react-spinners';

const Countries = [
  { label: "Albania", value: 355 },
  { label: "Argentina", value: 54 },
  { label: "Austria", value: 43 },
  { label: "Cocos Islands", value: 61 },
  { label: "Kuwait", value: 965 },
  { label: "Sweden", value: 46 },
  { label: "Venezuela", value: 58 }
];

const testData =[
  {
    label: 'Alabama',
    population: 4780127,
    capital: 'Montgomery',
    region: 'South',
  },
  { label: 'Alaska', population: 710249, capital: 'Juneau', region: 'West' },
  { label: 'Arizona', population: 6392307, capital: 'Phoenix', region: 'West' },
  {
    label: 'Arkansas',
    population: 2915958,
    capital: 'Little Rock',
    region: 'South',
  },
]


 function PredmetNastavnik() {

  const [nazivPredmeta, setNaziv] = useState("");
  const [sifraPredmeta, setSifraPredmeta] = useState("");
  const [nazivStudenataPredmeta, setBrojStudenataPredmeta] = useState("");
  const [nastavnici, setNastavniciData] = useState([]);
  const [predmeti, setPredmetiData] = useState([])
  const [selectedValue, setSelectedValue] = useState("");
  const [multiSelections, setMultiSelections] = useState([]);
  const [multiSelectionsOdsjek, setMultiSelectionsOdsjek] = useState([]);
  const [predmet, setPredmet] = useState();
  const [isLoading, setIsLoading] = useState(false);
  
console.log(predmeti);

  const odsjeciID = [];
  multiSelectionsOdsjek.map((e)=>{
    odsjeciID.push(e._id)
  })
  const nastavnikID = [];
  multiSelections.map((e)=>{
    nastavnikID.push(e._id)
  })

  // console.log(odsjeciID)
  // console.log(nastavnikID)
  
  // console.log("nastavnikID", nastavnikID);

  const handleNazivPredmeta = (e) => {
    setNaziv(e.target.value);
  };
  const handleSifraPredmeta = (e) => {
    setSifraPredmeta(e.target.value);
  };
  const handleBrojStudenataPredmeta = (e) => {
    setBrojStudenataPredmeta(e.target.value);
  };

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };


  // console.log(multiSelections )
  const formData = {
    nazivPredmeta: nazivPredmeta,
    odsjek: odsjeciID,
    nastavnik: nastavnikID,
    sifraPredmeta: sifraPredmeta,
    brojStudenata: nazivStudenataPredmeta

  }
  // console.log(formData)
  const [odsjeciData, setOdsjeciData] = useState([]);
    useEffect(() => {
      setIsLoading(true); // Postavite isLoading na true pre učitavanja podataka
      axios.get(`${Url}/api/odsjeci/`)
        .then(res => {
          setOdsjeciData(res.data);
        })
        .catch(err => console.log(err));

        axios.get(`${Url}/api/nastavnici/`)
        .then(res => {
          setNastavniciData(res.data);
        })
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false); // Postavite isLoading na false nakon što se podaci učitaju
        });

        axios.get(`${Url}/api/predmeti/`)
        .then(res => {
          setPredmetiData(res.data);
        })
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false); // Postavite isLoading na false nakon što se podaci učitaju
        });
    }, []);
//  console.log(formData )
  //Cubmit axios form
  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${Url}/api/predmet`, formData)
      .then(response => {
        alert("Uspesno ste kreirali predemt")
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Form onSubmit={handleSubmit} className="asuFormular">
    <h1>Dodavanje predmeta nastavniku</h1>
    {isLoading ? (
         <div className="loader-container" 
         style={{
           position: 'fixed',
           top: '50%',
           left: '50%',
           transform: 'translate(-50%, -50%)',
         }}>
         <DotLoader
           color={'#007bff'} // Boja spiner-a
           loading={isLoading}
           css={css`
             display: block;
             margin: 0 auto;
           `}
         />
       </div> // Prikazuje loader dok se podaci učitavaju
    ) : (
      // Prikazuje formular kada se podaci učitaju
      <>
<Form.Group style={{ marginTop: '20px' }} className="mb-3 formaUnos">
    <Form.Label className="fw-bold">Odsjek</Form.Label>
        <Typeahead
          id="basic-typeahead-multiple"
          labelKey={option => `${option.nazivOdsjeka}`}
          multiple  
          onChange={setMultiSelectionsOdsjek}
          options={odsjeciData}
          placeholder="Odaberi odsjek..."
          selected={multiSelectionsOdsjek}
        />
      </Form.Group>
    <Form.Group style={{ marginTop: '20px' }} className="mb-3 formaUnos">
    <Form.Label className="fw-bold">Nastavnik</Form.Label>
<Typeahead
  id="basic-typeahead-multiple"
  labelKey={option => `${option.ime} ${option.prezime}`} // Prikazuje ime i prezime
  multiple  
  onChange={setMultiSelections}
  options={nastavnici} // Koristi ceo objekat nastavnika za opcije
  placeholder="Odaberi..."
  selected={multiSelections}
/>
</Form.Group>
<Form.Group style={{ marginTop: '20px' }} className="mb-3 formaUnos">
    <Form.Label className="fw-bold">Predmet</Form.Label>
<Typeahead
  id="basic-typeahead-multiple"
  labelKey={option => `${option.sifraPredmeta} ${option.nazivPredmeta}`}// Prikazuje ime i prezime
//   multiple  
  onChange={setPredmet}
  options={predmeti} // Koristi ceo objekat nastavnika za opcije
  placeholder="Odaberi..."
  selected={predmet}
/>
</Form.Group>
   
  <br/>
    <Button variant="primary" type="submit">
      Spasi
    </Button>
    </>
    )}
    <br/>
  </Form>

  );
}

export default PredmetNastavnik;