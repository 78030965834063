import React, { useState,useEffect,useContext } from 'react';
import axios from 'axios';
import Url from '../api/defUrl';
import jwt_decode from 'jwt-decode';
import { UserContext } from '../../components/context/UserContext';
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";



// import image from "./Images/image.jpg";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://github.com/skarky">
        SK
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    // backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],

    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  size: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },

  paper: {
    margin: theme.spacing(2, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));
const Login = ({ handleLogin }) => {
    const classes = useStyles();
const { updateUser } = useContext(UserContext);
const [username, setUsername] = useState('');
const [password, setPassword] = useState('');
const [response, setResponse] = useState("")
const [userRole, setUserRole] = useState('');


const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const response = await axios.post(`${Url}/api/login`, { username, password });
    // console.log("response", response)
   
    // setResponse(response)
    localStorage.setItem('token', response.data.token);
    
    const decoded = jwt_decode(response.data.token);

      // Izvuci ulogu iz dekodiranih podataka
      const { role,userId } = decoded;
      // console.log(role, userId)
      
      // localStorage.setItem('rola', role);
      // Ažuriranje userId i role u kontekstu

    updateUser(userId, role);
    // localStorage.setItem('rola', userRole);
      window.location.href = '/';
   
   
  } catch (error) {
    console.error(error);
  }
};



  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}
      <Grid
        className={classes.size}
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={1}
        square
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
           Prijava
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit }>
            <TextField
            onChange={(event) => setUsername(event.target.value)} 
            value={ username }
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
            />
            <TextField
            onChange={(event) => setPassword(event.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            //   onClick = {handelLogin}
            >
                       Prijava korisnika
            </Button>
            <Grid container>
              <Grid item>
                <Link href="/#/registracija" variant="body2">
                  {"Nemate pristupne podatke? Zatražite ih"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default Login;