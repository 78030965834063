import "./userList.css";
// import { DataGrid } from "@material-ui/data-grid";
// import { DataGrid } from '@mui/x-data-grid';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { userRows } from "../../dummyData";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import Url from '../api/defUrl';

import ClipLoader from "react-spinners/ClipLoader";

export default function UserList() {
  // const [data, setData] = useState(userRows);

  let [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  // console.log(data)
    useEffect(() => {
      const controller = new AbortController();
      setLoading(true);
      axios.get(`${Url}/api/nastavnici/`,{
        signal: controller.signal,
      })
        .then(res => {
         setData(res?.data);
         setLoading(false);
        })
        .catch(err => console.log(err));
        return () => controller.abort();
    }, []);
    // useEffect(() => {
    //   const get = async () => {
    //   try{
    //       await axios.get("http://localhost:6200/api/nastavnici").then((res) => {
    //         setData(res.data);
    //       });
    //       console.log(data);
    //   } catch (e){
    //   console.error(e)
    //   }
    //   };
    //    get();
    //   }, []);

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };
  
  const columns = [
    { field: "_", headerName: "rb",
    // render: rowData => rowData.tableData.id + 1,
    width: 100 
  },
    // { field: "ime", headerName: "Ime", width: 100 },
    // { field: "prezime", headerName: "Prezime", width: 300 },
    {
      field: "ime",
      headerName: "Ime i Prezime",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            <p><Link to={`/nastavnik/${params.row._id}/ankete`}>{`${params.row.ime} ${params.row.prezime}`}</Link></p>
          </div>
        );
      },
    },
    {
      field: "predmet",
      headerName: "Predmeti",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            <p>{params.row.predmet.length}</p>
          </div>
        );
      },
    },
    {
      field: "anketa",
      headerName: "Ankete",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            <p>{params.row.anketa.length}</p>
          </div>
        );
      },
    },
    // {
    //   field: "user",
    //   headerName: "User",
    //   width: 200,
    //   renderCell: (params) => {
    //     return (
    //       <div className="userListUser">
    //         <img className="userListImg" src={params.row.avatar} alt="" />
    //         {params.row.username}
    //       </div>
    //     );
    //   },
    // },
    // { field: "email", headerName: "Email", width: 200 },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 120,
    // },
    // {
    //   field: "transaction",
    //   headerName: "Transaction Volume",
    //   width: 160,
    // },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Link to={"/user/" + params.row.id}>
    //           <button className="userListEdit">Edit</button>
    //         </Link>
    //         <DeleteOutline
    //           className="userListDelete"
    //           onClick={() => handleDelete(params.row.id)}
    //         />
    //       </>
    //     );
    //   },
    // },
  ];
  if (loading) {
    return (
        <div style={{ display: 'flex', marginLeft:"30%",justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ClipLoader color="#36D7B7" loading={loading} size={150} />
      </div>
    );
  }
  return (
    <div className="userList">
       <Link to={"/nastavnik-novi"}>
       <button className="productListEdit">Novi nastavnik</button>
       </Link>
      <DataGrid
        rows={data}
        // disableSelectionOnClick
        columns={columns}
        getRowId={(row) => row?._id}
        pageSize={16}
        headerHeight={65}
        rowHeight={45}
        
        // loading={loading}
        // checkboxSelection
      />
    </div>
  );
}
