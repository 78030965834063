import { useState, useEffect, createContext, useContext } from "react";
import { Typography, Select, MenuItem, List, ListItem, ListItemText } from '@mui/material';
import axios from 'axios';
import Chart from "../../components/chart/Chart";
import Box from '@material-ui/core/Box';
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "../home/home.css";
import Url from '../api/defUrl'
// import { userData } from "../../dummyData";
// import WidgetSm from "../../components/widgetSm/WidgetSm";
// import WidgetLg from "../../components/widgetLg/WidgetLg";
import { AnketaContext } from '../../components/context/ContextComponent';
import BirajNastavnikaIzvjestaj from "../../components/widgetSm/BirajNastavnikaIzvjestaj";
import TemplateOdgovora from "../../components/widgetSm/TemplateOdgovora";
import { Form, Button, Col } from "react-bootstrap";
import TemplateOdgovoraOdsjek from "../../components/widgetSm/TemplateOdgovoraOdsjek";

export default function InzvjestajOdsjek() {

    const [anketeData, setAnketeData]               = useState([]);
    const [nastavniciData, setNastavniciData]       = useState([]);
    const [odsjeciData, setOdsjeciData]             = useState([]);
    const [odsjeciSelectData, setOdsjeciSelectData]             = useState([]);
    const [predmetiData, setPredmetiData]           = useState([]);
    const [nastavnikAnketa, setNastavnikAnketa]     = useState("");
    const [predmetAnketa, setPredmetkAnketa]        = useState("");
    const [odsjekId, setOdsjekPredmetAnkete] = useState("")
    const [filtriraneAnketeAll, setFiltriraneAnkete] = useState([])
    const [brojStudenata, setBrojStudenata] = useState(0)
    const [loading, setLoading] = useState(true);
    const [selectedSemester, setSelectedSemester] = useState(""); // Dodajte stanje za odabir semestra


    // const  {odsjekPredmetAnkete, setOdsjekPredmetAnkete}  = useContext(AnketaContext);
  // Dodajte opciju za selektovanje semestra
  const semesters = ["zimski", "ljetni"]; // Dodajte ovdje sve željene opcije
  const semesterOptions = semesters.map((semester) => (
    <MenuItem key={semester} value={semester}>
      {semester}
    </MenuItem>
  ));
    // console.log("odsjeciData", odsjeciData)
    // console.log(odsjeciData)
// Dodajte useEffect za ažuriranje podataka kada se promijeni odsjekId
useEffect(() => {

  function ukloniPredmeteBezOdabranogSemestra(odsjeci, odabraniSemestar) {
    return odsjeci.map(function (odsjek) {
      odsjek.predmet = odsjek.predmet.filter(function (predmet) {
        // console.log(`Predmet semestar: ${predmet.semestar}, Odabrani semestar: ${odabraniSemestar}`);
        return predmet.semestar !== "zimski";
      });
      return odsjek;
    }).filter(function (odsjek) {
      return odsjek.predmet.length > 0;
    });
  }
  

// Poziv funkcije i ispis rezultata
// var odsjeciSaOdabranimSemestrom = ukloniPredmeteBezOdabranogSemestra(odsjeciData, selectedSemester);
// console.log('odsjeciSaOdabranimSemestrom',odsjeciSaOdabranimSemestrom);
  // Filtriranje anketa i akumulacija broja studenata
  const filtriraneAnkete = odsjeciData.flatMap((odsjek) => {
    if (odsjekId && odsjekId !== odsjek._id) {
      return []; // Preskoči odsjek ako je zadat odsjekId i ne odgovara
    }
    return odsjek.predmet.flatMap((predmet) => predmet.anketa);
  });
// console.log(filtriraneAnkete)
  const brojStudenata = odsjeciData.reduce((akumulator, odsjek) => {
    if (odsjekId && odsjekId !== odsjek.id) {
      return akumulator; // Preskoči odsjek ako je zadat odsjekId i ne odgovara
    }
    return odsjek.predmet.reduce((akumulatorPredmeta, predmet) =>
      akumulatorPredmeta + predmet.brojStudenata * predmet.nastavnik.length, akumulator);
  }, 0);
  
  // console.log(brojStudenata)
  setFiltriraneAnkete(filtriraneAnkete)
  setBrojStudenata(brojStudenata)
  // console.log(filtriraneAnkete);
  // console.log(filtriraneAnkete);

  // Ovdje možete ažurirati ostale state-ove s filtriranim ankete i brojem studenata

}, [odsjekId, odsjeciData]); // Dodajte odsjekId i odsjekPredmetAnkete kao zavisnosti useEffect-a


    useEffect(() => {
        // axios.get(`${Url}/api/anketa/`)
        //   .then(res => {
        //     setAnketeData(res.data);
        //   })
        //   .catch(err => console.log(err));
        axios.get(`${Url}/api/odsjeci/`)
          .then(res => {
            setOdsjeciData(res.data);
            setOdsjeciSelectData(res.data)
            setLoading(false);
          })
          .catch(err => console.log(err));
    
          // axios.get(`${Url}/api/predmeti/`)
          // .then(res => {
          //   setPredmetiData(res.data);
          //   // console.log(res.data)
          // })
          // axios.get(`${Url}/api/nastavnici/`)
          // .then(res => {
          //   setNastavniciData(res.data);
          // })
          // .catch(err => console.log(err));
      }, []);

      if (!odsjeciData) {
        return <p>Učitavanje podataka...</p>;
      }

        let nasank = []
		
      nastavniciData.map((e)=>{
          if(e._id == nastavnikAnketa){
              nasank.push(e)
            //   setSelektovanNastavni(e)
          }
      })
    //   console.log(nasank)
      let nastavnik_predmet = []
		//predmetAnketa je ID ankete
        nasank[0]?.predmet?.map((e)=>{
          if(e._id == predmetAnketa){
            nastavnik_predmet.push(e)
            //   setSelektovanNastavni(e)
          }
      })
    
      let odsjeci = odsjeciData?.map((x)=>{
        // return <option value={`predmetID:${x._id},Odsjek:${x?.odsjek?.map((y)=>y.nazivOdsjeka)}`}>{x.nazivPredmeta}</option>
        return <option value={`${x._id}`}>{x.nazivOdsjeka}</option>
    })
    // let odsjekPredmetAnkete = []
 
    
    const handleSelectChange = (e) =>{
      setOdsjekPredmetAnkete(e)
    }

   

//   // console.log(odsjekPredmetAnkete)
// //Filtriranje anketa i akumulacija broja studenata
// const filtriraneAnkete = odsjeciData?.flatMap((odsjek) =>
// odsjek.predmet?.flatMap((predmet) => predmet.anketa)
// );

// const brojStudenata = odsjeciData?.reduce((akumulator, odsjek) =>
// odsjek?.predmet?.reduce((brojStudenataPredmeta, predmet) =>
//   brojStudenataPredmeta + predmet.brojStudenata, akumulator), 0
// );

// console.log(filtriraneAnkete);
// console.log(brojStudenata);


if (loading) {
  return <p>Podaci se učitavaju...</p>;
}
    // console.log(odsjekPredmetAnkete)
  return (
    <div className="home">

{/* <AnketaContext.Provider value={{odsjekPredmetIzvjestaj, anketeData, predmetiData, nastavniciData, setNastavnikAnketa, setPredmetkAnketa, setOdsjekPredmetIzvjestaj}}> */}
      <FeaturedInfo />
      {/* <Chart data={userData} title="User Analytics" grid dataKey="Active User"/> */}
      <div className="homeWidgets">

        
        <div className="widgetsm" style={{width:"100%"}}>
    <Box>
     <Typography variant="h5">Odaberi odsjek:</Typography>
<Select
  value={odsjeciSelectData}
  style={{ minWidth: 500 }}
  onChange={(e) => {
    setOdsjeciData(e.target.value);

// console.log(e.target.value)  
  }}
>
  <MenuItem  value="">Odaberite odsjek</MenuItem>
  {odsjeciSelectData?.map((odsjek) => (
    <MenuItem key={odsjek?._id} value={[odsjek]}>
      {odsjek?.nazivOdsjeka}
    </MenuItem>
  ))}
</Select>
 {/* Dodajte Select komponentu za odabir semestra */}
 {/* <Box>
            <Typography variant="h5">Odaberi semestar:</Typography>
            <Select
              value={selectedSemester}
              style={{ minWidth: 500 }}
              onChange={(e) => {
                setSelectedSemester(e.target.value);
              }}
            >
              <MenuItem value="">Odaberite semestar</MenuItem>
              {semesterOptions}
            </Select>
          </Box> */}
     </Box>
        <TemplateOdgovoraOdsjek sveAnkete={filtriraneAnketeAll} brojStudenata={brojStudenata} odsjek={odsjeciData}/>
      </div>
      </div>
  {/* </AnketaContext.Provider> */}
    </div>
  );
}

