import "./sidebar.css";
import React, { useContext } from 'react';
import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  Storefront,
  AttachMoney,
  BarChart,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
  Group,
  Settings,
  Poll,
  MenuBook
} from "@material-ui/icons";
// import { Link } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { UserContext } from '../../components/context/UserContext';

export default function Sidebar() {
  let { id } = useParams();

  // const { userId, role } = useContext(UserContext);
  const role = localStorage.getItem('role');
  const userId = localStorage.getItem('userId');

  // console.log(userId)
  return (
    <div className="sidebar">
      { role ==="admin" ?
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          {/* <h3 className="sidebarTitle">Dashboard</h3> */}
          <ul className="sidebarList">
            <Link to="/izvjestaji/asu" className="link">
            <li className="sidebarListItem active">
              <LineStyle className="sidebarIcon" />
              Pocetna
            </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Postavke</h3>
          <ul className="sidebarList">
          <Link to="/odsjeci" className="link">
              <li className="sidebarListItem">
              <Group className="sidebarIcon" />
                Odsjeci
              </li>
            </Link>
            {/* <Link to="/predmeti" className="link">
              <li className="sidebarListItem">
              <MenuBook className="sidebarIcon" />
                Predmeti
              </li>
            </Link> */}
            {/* <Link to="/predmet-nastavnik" className="link">
              <li className="sidebarListItem">
              <MenuBook className="sidebarIcon" />
                Predmeti-Nastavnik
              </li>
            </Link> */}
            <Link to="/nastavnici" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Nastavnici
              </li>
            </Link>
            <Link to="/ankete" className="link">
              <li className="sidebarListItem">
              <Poll className="sidebarIcon" />
                Ankete
              </li>
            </Link>
            {/* <Link to="/postavke" className="link">
              <li className="sidebarListItem">
              <Settings className="sidebarIcon" />
              Postavke
              </li>
            </Link> */}
          </ul>
          <h3 className="sidebarTitle">IZVJESTAJI</h3>
          <ul className="sidebarList">
          {/* <Link to="/izvjestaji/asu" className="link">
              <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Izvjestaj ASU
              </li>
            </Link> */}
            {/* <Link to="/izvjestaji/odsjeci" className="link">
              <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Izvjestaj odsjeci
              </li>
            </Link> */}
            <Link to="/izvjestaji" className="link">
              <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Izvjestaj nastavnici
              </li>
            </Link>
            <Link to="/odsjek/izvjestaji" className="link">
              <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Izvjestaj odsjeci
              </li>
            </Link>
            <Link to="/asu/izvjestaji" className="link">
              <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Izvjestaj asu
              </li>
            </Link>
            {/* <Link to="/asu/izvjestaji" className="link">
              <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Izvjestaj ASU
              </li>
            </Link> */}
            <Link to="/studenti-anketa" className="link">
              <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Nova anketa - student
              </li>
            </Link>
          </ul>
          <h3 className="sidebarTitle">EDIT 2024</h3>
          <ul className="sidebarList">
            {/* <Link to="/nastavnici2024" className="link">
              <li className="sidebarListItem">
              <MenuBook className="sidebarIcon" />
              Edit nastavnici-predmet
              </li>
            </Link> */}
            <Link to="/predmeti2024" className="link">
              <li className="sidebarListItem">
              <MenuBook className="sidebarIcon" />
              Predmeti
              </li>
            </Link>
            <Link to="/predmet/kood" className="link">
              <li className="sidebarListItem">
              <MenuBook className="sidebarIcon" />
              Predmet - kōd
              </li>
            </Link>
            <Link to={'/kood/svi/'} className="link">
              <li className="sidebarListItem">
              <Poll className="sidebarIcon" />
                Kodovi
              </li>
            </Link>
            {/* <Link to="/kood/novi" className="link">
              <li className="sidebarListItem">
              <MenuBook className="sidebarIcon" />
              Novi kōd
              </li>
            </Link> */}
            </ul>
        </div>
     
      </div>
      : 
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Postavke</h3>
          <ul className="sidebarList">
          <Link to={`/nastavnik/predmeti/${userId}`} className="link">
              <li className="sidebarListItem">
              <MenuBook className="sidebarIcon" />
                Predmeti
              </li>
            </Link>
            <Link to={`/nastavnik/ankete/${userId}`} className="link">
              <li className="sidebarListItem">
              <Poll className="sidebarIcon" />
                Ankete
              </li>
            </Link>
          
          </ul>
          <h3 className="sidebarTitle">IZVJESTAJI</h3>
          <ul className="sidebarList">
      
            <Link to={`/nastavnik/${userId}/ankete`} className="link">
              <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Izvjestaj ankete
              </li>
            </Link>
          </ul>
        </div>
       
      </div>
      }
    </div>
  );
}
