import React, { useState, useEffect, CSSProperties } from 'react';
import { useParams } from 'react-router-dom';
import Url from './api/defUrl';
import axios from 'axios';
import { Typography, List, ListItem, ListItemText, Button, Alert } from '@mui/material';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ClipLoader from "react-spinners/ClipLoader";

const PredmetKood = () => {
  const { id } = useParams();
  const [predmet, setPredmet] = useState([]);
  const [nastavnici, setNastavnici] = useState([]);
  const [kood, setKood] = useState([]);
//   const [odsjek, setOdsjek] = useState("");
    // console.log(predmet)
  const [selectedPredmeti, setSelectedPredmeti] = useState([]);
  const [selectedKood, setSelectedKood] = useState([]);
  const [selectedSemestar, setSelectedSemestar] = useState("");

  let [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchPredmet = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${Url}/api/predmeti`);
        setPredmet(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchPredmet();
  }, [id]);

  useEffect(() => {
    axios.get(`${Url}/api/kood/`)
      .then(res => {
        setKood(res?.data);
      })
      .catch(err => console.log(err));
  }, []);

//   useEffect(() => {
//     const fetchNastavnici = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.get(`${Url}/api/nastavnici`);
//         setNastavnici(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchNastavnici();
//   }, []);

     // Izvadite samo ID-jeve iz objekata i sačuvajte ih u novim state varijablama
     const predmetIds = selectedPredmeti.map((predmet) => predmet._id);
     const koodIds = selectedKood.map((k) => k._id);

  const handleSave = async () => {
    // Logika za ažuriranje podataka o predmetu
    try {
        setLoading(true);
      const res = await axios.post(`${Url}/api/predmeti/koodovi/`, {
        selectedPredmeti: predmetIds,
        selectedKood: koodIds,
        // Dodajte ostala polja koja želite ažurirati
      });

      if(res.status === 200){
        setLoading(false);
        alert("Bravo, uspjesno ste izmijenili podatke!")
      }else{
        alert("Upssss, imamo problem")
        setLoading(false);
      }

    //   setPredmet(res.data)
     
      // Ažurirajte stanje komponente ili preusmerite korisnika
    } catch (error) {
      console.error(error);
    }
  };

  const handleSemestarChange = (e) => {
    setSelectedSemestar(e.target.value);
  };


  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <ClipLoader color="#36D7B7" loading={loading} size={150} />
      </div>
    );
  }

  return (
    <div style={{ padding: "50px" }}>
      <Typography variant="h2">Dodjeljivanje koda predmetu</Typography>
      <p>
{/* 
        <label htmlFor="semestar">Odaberi semestar:</label>
      <select id="semestar" value={selectedSemestar} onChange={handleSemestarChange}>
        <option value="">Odaberi...</option>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((semestar) => (
          <option key={semestar} value={semestar}>
            {semestar}
          </option>
        ))}
      </select> */}

      {selectedSemestar && <p>Odabran semestar: {selectedSemestar}</p>}
      </p>
        <h3>Odaberite predmete:</h3>
        <Typeahead
        id="basic-typeahead-multiple"
        labelKey={(option) => `${option.sifraPredmeta}-${option.nazivPredmeta}`}
        multiple
        onChange={(selected) => setSelectedPredmeti(selected)}
        options={predmet}
        placeholder="Odaberi..."
        selected={selectedPredmeti}
      />
 
        <h3>Odaberite kodove:</h3>
        <Typeahead
        id="basic-typeahead-multiple"
        labelKey={(option) => `${option.koodId}`}
        multiple
        onChange={(selected) => setSelectedKood(selected)}
        options={kood}
        placeholder="Odaberi..."
        selected={selectedKood}
      />
      <Button variant="contained" style={{marginTop:20}} onClick={handleSave}>
        Sačuvaj izjmene
      </Button>
    </div>
  );
};

export default PredmetKood;
