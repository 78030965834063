import { useState, createContext, useContext } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { UserContext } from "./Context"
import Badge from 'react-bootstrap/Badge';

const BirajNastavnikaOdsjek = (data) =>{
    const  {odsjekPredmet}  = useContext(UserContext);
// console.log(typeof(odsjekPredmet))
    // if (typeof(odsjekPredmet) =="string" ){
    //     console.log("sead")
    //     } else{
    //          odsjekPredmet && odsjekPredmet?.map((e)=>{
    //             return  <>{e=="Odsjek za režiju" ? <Badge bg="success">{e}</Badge> 
    //             : e=="Odsjek za produkciju i menadžment u oblasti scenskih umjetnosti" ? <Badge bg="primary">{e}</Badge>
    //             : e=="Odsjek za dramaturgiju" ? <Badge bg="info">{e}</Badge>
    //             : ""}</>
    //         })
           
    //     }
    //     typeof(odsjekPredmet) =="string" ? 
    // console.log("dddddd",odsjekPredmet)
    // const handleSelectChange = (e)=>{
        // console.log(data.data.predmet)
    // }

    return(
        <>
         {
           
           typeof(odsjekPredmet) !=="string" ? 
           odsjekPredmet && odsjekPredmet?.map((e)=>{
            return  <>{e=="Odsjek za režiju" ? <Badge bg="success">{e}</Badge> 
            : e=="Odsjek za produkciju i menadžment u oblasti scenskih umjetnosti" ? <Badge bg="primary">{e}</Badge>
            : e=="Odsjek za dramaturgiju" ? <Badge bg="info">{e}</Badge>
            : e=="Odsjek za glumu" ? <Badge bg="warning">{e}</Badge>
            : e=="Odsjek za reziju (katedra AV)" ? <Badge bg="dark">{e}</Badge>
            : ""}</>
        })
            : ""
        }
        
    {/* {
        data.data?.map((e)=>{
        return <option value={`${e._id}-${e?.predmet?.map((e) => `${e._id} ${e.nazivPredmeta}`)}`}>{e.ime} {e.prezime}</option>
        })
    } */}
   
        </>
    )
}

export default BirajNastavnikaOdsjek;