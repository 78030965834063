// import "./newProduct.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect, createContext, useContext,useRef } from "react";
import { Form, Button, Col } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import zIndex from '@mui/material/styles/zIndex';
import { AnketaContext } from '../context/UserContext';
import FeaturedInfo from '../featuredInfo/FeaturedInfo';



 function TemplateOdgovoraUserDetalj(props) {

    // console.log("brojStudenata", props.brojStudenata)
    // console.log("props.nastavnik", props.nastavnik)
const broj_studenata_ukupno = props.brojStudenata

const printableContentRef = useRef(null);

const handlePrint = () => {
  const printableContent = printableContentRef.current;
  if (printableContent) {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(printableContent.innerHTML);
    printWindow.document.close();
    printWindow.print();
  }
};

    const odgvor1 = []
    const odgvor2 = []
    const odgvor3 = []
    const odgvor4 = []
    const odgvor21 = []
    const odgvor22 = []
    const odgvor23 = []
    const odgvor24 = []
    const odgvor31 = []
    const odgvor32 = []
    const odgvor33 = []
    const odgvor34 = []
    const odgvor41 = []
    const odgvor42 = []
    const odgvor43 = []
    const odgvor44 = []
    const odgvor45 = [] //text
    const odgvor51 = []
    const odgvor52 = []
    const odgvor53 = []
    const odgvor54 = []
    const odgvor55 = []
    const odgvor61 = []
    const odgvor62 = []
    const odgvor63 = []
    const odgvor64 = []
    const odgvor65 = []
    const odgvor71 = []
    const odgvor72 = []
    const odgvor73 = []
    const odgvor74 = []
    const odgvor81 = []
    const odgvor82 = []
    const odgvor83 = []
    const odgvor84 = []
    const odgvor91 = []
    const odgvor92 = []
    const odgvor93 = []
    const odgvor94 = []
    const odgvor95 = []
    const odgvor96 = []
    const odgvor101 = []
    const odgvor102 = []
    const odgvor103 = []
    const odgvor104 = []
    const odgvor111 = []
    const odgvor112 = []
    const odgvor113 = []
    const odgvor114 = []
    const odgvor115 = []
    const odgvor121 = []
    const odgvor122 = []
    const odgvor123 = []
    const odgvor124 = []
    const odgvor125 = []
    const odgvor126 = []
    const odgvorPitanje13 = []
    

    let finalniprikazData = []

       // Filtriraj nastavnike koji imaju predmet s odabranim ID-om
  const filtriraniObjekti = finalniprikazData?.filter((objekt) => {
    return objekt?.nastvanik[0] === props?.id;
  });

         
    console.log("nastavnik", props.nastavnik);
    
    props.nastavnik?.map((e,index)=>{
       
        if(e.pitanje1 == 'Redovno'){
            let pitanje1 = []
            let acc = ""
                acc += e.pitanje1
            pitanje1.push(acc)
            odgvor1.push(pitanje1.length)
            // console.log(pitanje1)
        }if(e.pitanje1 == 'Ne baš redovno'){
            let pitanje2 = []
            let acc = ""
                acc += e.pitanje2
            pitanje2.push(acc)
            odgvor2.push(pitanje2.length)
            // console.log(pitanje1)
        }if(e.pitanje1 == 'Neredovno'){
            let pitanje3 = []
            let acc = ""
                acc += e.pitanje3
            pitanje3.push(acc)
            odgvor3.push(pitanje3.length)
            // console.log(pitanje1)
        }if(e.pitanje1 == 'Nastave nije bilo'){
            let pitanje4 = []
            let acc = ""
                acc += e.pitanje4
            pitanje4.push(acc)
            odgvor4.push(pitanje4.length)
            // console.log(pitanje1)
        }
//PITANJE 2
        if(e.pitanje2 == 'Nastava je izvođena po rasporedu, bez većih odstupanja'){
            let pitanje1 = []
            let acc = ""
                acc += e.pitanje1
            pitanje1.push(acc)
            odgvor21.push(pitanje1.length)
            // console.log(pitanje1)
        }if(e.pitanje2 == 'Nastava je izvođena po rasporedu, sa manjim odstupanjima'){
            let pitanje2 = []
            let acc = ""
                acc += e.pitanje2
            pitanje2.push(acc)
            odgvor22.push(pitanje2.length)
            // console.log(pitanje1)
        }if(e.pitanje2 == 'Nastava je izvođena sa velikim odstupanjima od rasporeda'){
            let pitanje3 = []
            let acc = ""
                acc += e.pitanje3
            pitanje3.push(acc)
            odgvor23.push(pitanje3.length)
            // console.log(pitanje1)
        }if(e.pitanje2 == 'Nastava nije izvođena po rasporedu'){
            let pitanje4 = []
            let acc = ""
                acc += e.pitanje4
            pitanje4.push(acc)
            odgvor24.push(pitanje4.length)
            // console.log(pitanje1)
        }
//PITANJE 3
        if(e.pitanje3 == 'Vrlo zadovoljna/an'){
            let pitanje1 = []
            let acc = ""
                acc += e.pitanje1
            pitanje1.push(acc)
            odgvor31.push(pitanje1.length)
            // console.log(pitanje1)
        }if(e.pitanje3 == 'Zadovoljana/an'){
            let pitanje2 = []
            let acc = ""
                acc += e.pitanje2
            pitanje2.push(acc)
            odgvor32.push(pitanje2.length)
            // console.log(pitanje1)
        }if(e.pitanje3 == 'Djelomično zadovoljna/an'){
            let pitanje3 = []
            let acc = ""
                acc += e.pitanje3
            pitanje3.push(acc)
            odgvor33.push(pitanje3.length)
            // console.log(pitanje1)
        }if(e.pitanje3 == 'Nisam zadovoljna/an'){
            let pitanje4 = []
            let acc = ""
                acc += e.pitanje4
            pitanje4.push(acc)
            odgvor34.push(pitanje4.length)
            // console.log(pitanje1)
        }
        //PITANJE 4
        if(e?.pitanje4 == 'Vrlo zadovoljna/an'){
          let pitanje1 = []
          let acc = ""
              acc += e?.pitanje1
          pitanje1.push(acc)
          odgvor41.push(pitanje1.length)

      }if(e?.pitanje4 == 'Djelomično zadovoljna/an'){
          let pitanje3 = []
          let acc = ""
              acc += e?.pitanje3
          pitanje3.push(acc)
          odgvor43.push(pitanje3.length)
      }if(e?.pitanje4 == 'Nisam zadovoljna/an'){
          let pitanje4 = []
          let acc = ""
              acc += e?.pitanje4
          pitanje4.push(acc)
          odgvor44.push(pitanje4.length)
      }
         //PITANJE 5
        if(e?.pitanje5 == 'Uglavnom'){
          let pitanje2 = []
          let acc = ""
              acc += e?.pitanje2
          pitanje2.push(acc)
          odgvor52.push(pitanje2.length)
      }if(e?.pitanje5 == 'Ponekad'){
          let pitanje3 = []
          let acc = ""
              acc += e?.pitanje3
          pitanje3.push(acc)
          odgvor53.push(pitanje3.length)
      }if(e?.pitanje5 == 'Nikada'){
          let pitanje4 = []
          let acc = ""
              acc += e?.pitanje4
          pitanje4.push(acc)
          odgvor54.push(pitanje4.length)
      
         //PITANJE 6
        }if(e?.pitanje6 == 'Uglavnom'){
          let pitanje2 = []
          let acc = ""
              acc += e?.pitanje2
          pitanje2.push(acc)
          odgvor62.push(pitanje2.length)
          // console.log(pitanje1)
      }if(e?.pitanje6 == 'Ponekad'){
          let pitanje3 = []
          let acc = ""
              acc += e?.pitanje3
          pitanje3.push(acc)
          odgvor63.push(pitanje3.length)
          // console.log(pitanje1)
      }if(e?.pitanje6 == 'Nikada'){
          let pitanje4 = []
          let acc = ""
              acc += e?.pitanje4
          pitanje4.push(acc)
          odgvor64.push(pitanje4.length)

      }
                 //PITANJE 7
          if(e?.pitanje7 == 'Uglavnom'){
                  let pitanje2 = []
                  let acc = ""
                      acc += e?.pitanje2
                  pitanje2.push(acc)
                  odgvor72.push(pitanje2.length)
                  // console.log(pitanje1)
      }if(e?.pitanje7 == 'Ponekad'){
      let pitanje3 = []
      let acc = ""
          acc += e?.pitanje3
          pitanje3.push(acc)
          odgvor73.push(pitanje3.length)
           // console.log(pitanje1)
      }if(e?.pitanje7 == 'Nikada'){
      let pitanje4 = []
      let acc = ""
          acc += e?.pitanje4
          pitanje4.push(acc)
          odgvor74.push(pitanje4.length)
          // console.log(pitanje1)
      }
        if(e.pitanje8 == 'Potpuno su i stalno dostupni'){
            let pitanje1 = []
            let acc = ""
                acc += e.pitanje1
            pitanje1.push(acc)
            odgvor81.push(pitanje1.length)
            // console.log(pitanje1)
        }if(e.pitanje8 == 'Uglavnom su na raspolaganju (uz manje nedostatke)'){
            let pitanje2 = []
            let acc = ""
                acc += e.pitanje2
            pitanje2.push(acc)
            odgvor82.push(pitanje2.length)
            // console.log(pitanje1)
        }if(e.pitanje8 == 'Postoje, ali su uglavnom nedostupni'){
            let pitanje3 = []
            let acc = ""
                acc += e.pitanje3
                pitanje3.push(acc)
                odgvor83.push(pitanje3.length)
                // console.log(pitanje1)
        }if(e.pitanje8 == 'Nisu postojali i ne znam koji su'){
            let pitanje4 = []
            let acc = ""
                acc += e.pitanje4
                pitanje4.push(acc)
                odgvor84.push(pitanje4.length)
                // console.log(pitanje1)
        }
        if(e?.pitanje9 == 'Uglavnom'){
          let pitanje3 = []
          let acc = ""
              acc += e?.pitanje3
              pitanje3.push(acc)
              odgvor113.push(pitanje3.length)
              // console.log(pitanje1)
      }if(e?.pitanje9 == 'Ponekad'){
          let pitanje4 = []
          let acc = ""
              acc += e?.pitanje4
              pitanje4.push(acc)
              odgvor114.push(pitanje4.length)
              // console.log(pitanje1)
      }if(e?.pitanje9 == 'Nikada'){
          let pitanje4 = []
          let acc = ""
              acc += e?.pitanje4
              pitanje4.push(acc)
              odgvor115.push(pitanje4.length)
      }
      if(e?.pitanje10 == 'Uglavnom'){
        let pitanje3 = []
        let acc = ""
            acc += e?.pitanje3
            pitanje3.push(acc)
            odgvor123.push(pitanje3.length)
            // console.log(pitanje1)
    }if(e?.pitanje10 == 'Ponekad'){
        let pitanje4 = []
        let acc = ""
            acc += e?.pitanje4
            pitanje4.push(acc)
            odgvor124.push(pitanje4.length)
            // console.log(pitanje1)
    }if(e?.pitanje10 == 'Nikada'){
        let pitanje5 = []
        let acc = ""
            acc += e?.pitanje5
            pitanje5.push(acc)
            odgvor125.push(pitanje5.length)
            // console.log(pitanje1)
    }
        // if(e.pitanje10.option1 == true){
        //     let pitanje1 = []
        //     let acc = ""
        //         acc += e.pitanje1
        //     pitanje1.push(acc)
        //     odgvor101.push(pitanje1.length)
        //     // console.log(pitanje1)
        // }if(e.pitanje10.option2 == true){
        //     let pitanje2 = []
        //     let acc = ""
        //         acc += e.pitanje2
        //     pitanje2.push(acc)
        //     odgvor102.push(pitanje2.length)
        //     // console.log(pitanje1)
        // }if(e.pitanje10.option3 == true){
        //     let pitanje3 = []
        //     let acc = ""
        //         acc += e.pitanje3
        //         pitanje3.push(acc)
        //         odgvor103.push(pitanje3.length)
        //         // console.log(pitanje1)
        // }if(e.pitanje10.option4 == true){
        //     let pitanje4 = []
        //     let acc = ""
        //         acc += e.pitanje4
        //         pitanje4.push(acc)
        //         odgvor104.push(pitanje4.length)
        //         // console.log(pitanje1)
        // }


        if(e?.pitanje12 == 'Uglavnom'){
          let pitanje3 = []
          let acc = ""
              acc += e?.pitanje3
              pitanje3.push(acc)
              odgvor94.push(pitanje3.length)
              // console.log(pitanje1)
      }if(e?.pitanje12 == 'Ponekad'){
          let pitanje4 = []
          let acc = ""
              acc += e?.pitanje4
              pitanje4.push(acc)
              odgvor95.push(pitanje4.length)
              // console.log(pitanje1)
      }if(e?.pitanje12 == 'Nikada'){
          let pitanje5 = []
          let acc = ""
              acc += e?.pitanje5
              pitanje5.push(acc)
              odgvor96.push(pitanje5.length)
              // console.log(pitanje1)
      }if(e.pitanje13 !== null){
            odgvorPitanje13.push(
              <li style={{ marginBottom: 0 }}>
               {e.pitanje13}
              </li>
            );
      }

    //     if(e.pitanje12.pitanje == 'U potpunosti'){
    //         let pitanje1 = []
    //         let acc = ""
    //             acc += e.pitanje1
    //         pitanje1.push(acc)
    //         odgvor121.push(pitanje1.length)
    //         // console.log(pitanje1)
    //     }if(e.pitanje12.pitanje == 'U većini slučajeva'){
    //         let pitanje2 = []
    //         let acc = ""
    //             acc += e.pitanje2
    //         pitanje2.push(acc)
    //         odgvor122.push(pitanje2.length)
    //         // console.log(pitanje1)
    //     }if(e.pitanje12.pitanje == 'Uglavnom'){
    //         let pitanje3 = []
    //         let acc = ""
    //             acc += e.pitanje3
    //             pitanje3.push(acc)
    //             odgvor123.push(pitanje3.length)
    //             // console.log(pitanje1)
    //     }if(e.pitanje12.pitanje == 'Ponekad'){
    //         let pitanje4 = []
    //         let acc = ""
    //             acc += e.pitanje4
    //             pitanje4.push(acc)
    //             odgvor124.push(pitanje4.length)
    //             // console.log(pitanje1)
    //     }if(e.pitanje12.pitanje == 'Nikada'){
    //         let pitanje5 = []
    //         let acc = ""
    //             acc += e.pitanje5
    //             pitanje5.push(acc)
    //             odgvor125.push(pitanje5.length)
    //             // console.log(pitanje1)
    //     }if(e.pitanje12.tekst !== null){
    //       odgvor126.push(
    //         <li style={{ marginBottom: 0 }}>
    //         {e.pitanje12.tekst}
    //         </li>
    //       );

    //   }if(e.pitanje13 !== null){
    //     odgvorPitanje13.push(
    //       <li style={{ marginBottom: 0 }}>
    //        {e.pitanje13}
    //       </li>
    //     );
    // }
    })
   
    // console.log("odgvor45",odgvor45)
    // console.log(odgvor102.length)
    //     console.log(odgvor103.length)
    //     console.log(odgvor104.length)

const suma_odgovora = odgvor1.length + odgvor2.length + odgvor3.length + odgvor4.length;
const suma_odgovora2 = odgvor21.length + odgvor22.length + odgvor23.length + odgvor24.length;
const suma_odgovora3 = odgvor31.length + odgvor32.length + odgvor33.length + odgvor34.length;
const suma_odgovora4 = odgvor41.length + odgvor42.length + odgvor43.length + odgvor44.length;
const suma_odgovora5 = odgvor51.length + odgvor52.length + odgvor53.length + odgvor54.length;
const suma_odgovora6 = odgvor61.length + odgvor62.length + odgvor63.length + odgvor64.length;
const suma_odgovora7 = odgvor71.length + odgvor72.length + odgvor73.length + odgvor74.length;
const suma_odgovora8 = odgvor81.length + odgvor82.length + odgvor83.length + odgvor84.length;
const suma_odgovora11 = odgvor94.length+ odgvor95.length+ odgvor96.length;//11
// console.log(suma_odgovora9)
// console.log(odgvor94.length)
// console.log(odgvor95.length)
// console.log(odgvor96.length)
// const suma_odgovora9 = odgvor91.length + odgvor92.length + odgvor93.length + odgvor94.length+ odgvor95.length+ odgvor96.length;
// const suma_odgovora10 = odgvor101.length + odgvor102.length + odgvor103.length + odgvor104.length;
const suma_odgovora9 = odgvor113.length + odgvor114.length+ odgvor115.length;
const suma_odgovora10 =  odgvor123.length + odgvor124.length+ odgvor125.length;

let suma_procenata = 0
const prosjekOdgovori = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata2 = 0
const prosjekOdgovori2 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata2 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}

let suma_procenata3 = 0
const prosjekOdgovori3 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata3 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata4 = 0
const prosjekOdgovori4 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata4 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata5 = 0
const prosjekOdgovori5 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata5 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata6 = 0
const prosjekOdgovori6 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata6 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata7 = 0
const prosjekOdgovori7 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata7 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata8 = 0
const prosjekOdgovori8 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata8 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata9 = 0
const prosjekOdgovori9 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata9 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata111 = 0
const prosjekOdgovori111 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata111 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata11 = 0
const prosjekOdgovori11 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata11 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}
let suma_procenata12 = 0
const prosjekOdgovori12 = (brojOdgovora, broj_studenata_ukupno) =>{
    suma_procenata12 += Number(((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2))
    return ((brojOdgovora/broj_studenata_ukupno)*100).toFixed(2)
}

    const duzinaOdg1= [];
    const duzinaOdg2=[];
    const duzinaOdg3=[];
    const duzinaOdg4=[];


    if(odgvor101.length > 1){
        duzinaOdg1.push(odgvor101.length/2)
    } else{
        duzinaOdg1.push(odgvor101)
    }if(odgvor102.length > 1){
        duzinaOdg2.push(odgvor102.length/2)
    } else{
        duzinaOdg2.push(odgvor102)
    }if(odgvor103.length > 1){
        duzinaOdg3.push(odgvor103.length/2)
    } else{
        duzinaOdg3.push(odgvor103)
    }if(odgvor104.length > 1){
        duzinaOdg4.push(odgvor104.length/2)
    }else{
        duzinaOdg4.push(odgvor104)
    }


  return (
    <>
    <Button style={{float:"right"}} onClick={handlePrint}>Printaj</Button>
    <Form className="asuFormular" ref={printableContentRef}>
    <h5>UNIVERZITET U SARAJEVU - AKADEMIJA SCENSKIH UMJETNOSTI</h5>
<p style={{ padding: 0, marginBottom: 0 }}>
  Odsjek: {props?.predmet.odsjek ? props?.predmet.odsjek[0].nazivOdsjeka : props.svipredmeti.reduce((acc, curr) => {
    const odsjeci = curr.odsjek.map((f) => f.nazivOdsjeka);
    return [...acc, ...odsjeci];
  }, []).filter((value, index, self) => self.indexOf(value) === index).join(', ')}
</p>
<p style={{ padding: 0, marginBottom: 0 }}>
  Sifra predmeta: {props?.predmet.sifraPredmeta ? props?.predmet.sifraPredmeta : props.svipredmeti.map((e) => e.sifraPredmeta + ', ')}
</p>
<p style={{ padding: 0, marginBottom: 0 }}>Nastavnik: {props.nastavnikIme.ime} {props.nastavnikIme.prezime}</p>

     <Table responsive="sm">
        <thead>
          <tr>
            <th>1</th>
            <th>Nastava na predmetu je izvođena:</th>
            <th>Broj odgovora</th>
            <th>Procenat %{}</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>1.1</td>
            <td>Nastave nije bilo</td>
            <td>{odgvor4.length}</td>
            <td>{prosjekOdgovori(odgvor4.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>1.2</td>
            <td>Nastava nije bila redovna</td>
            <td>{odgvor3.length}</td>
            <td>{prosjekOdgovori(odgvor3.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>1.3</td>
            <td>Nastave je bila redovna</td>
            <td>{odgvor1.length}</td>
            <td>{prosjekOdgovori(odgvor1.length,broj_studenata_ukupno)}</td>
          </tr>
          {/* <tr>
            <td>1.1</td>
            <td>Redovno</td>
            <td>{odgvor1.length}</td>
            <td>{prosjekOdgovori(odgvor1.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>1.2</td>
            <td>Ne baš redovno</td>
            <td>{odgvor2.length}</td>
            <td>{prosjekOdgovori(odgvor2.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>1.3</td>
            <td>Neredovno</td>
            <td>{odgvor3.length}</td>
            <td>{prosjekOdgovori(odgvor3.length,broj_studenata_ukupno)}</td>
          </tr> */}
          {/* <tr>
            <td>1.4</td>
            <td>Nastave nije bilo</td>
            <td>{odgvor4.length}</td>
            <td>{prosjekOdgovori(odgvor4.length,broj_studenata_ukupno)}</td>
          </tr> */}
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora}({broj_studenata_ukupno})</td>
            <td>{suma_procenata.toFixed(2)}%</td>
          </tr>
       
        </tbody>
   {/* 2 odgovor */}
        <thead>
          <tr>
            <th>2</th>
            <th>Da li je nastava izvođena u skladu sa rasporedom nastave?:</th>
            {/* <th>Broj odgovora</th>
            <th>Procenat %{}</th> */}
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>2.1</td>
            <td>Nastava nije izvođena po rasporedu</td>
            <td>{odgvor24.length}</td>
            <td>{prosjekOdgovori2(odgvor24.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>2.2</td>
            <td>Nastava je izvođena po rasporedu, sa većim odstupanjima</td>
            <td>{odgvor23.length}</td>
            <td>{prosjekOdgovori2(odgvor23.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>2.3</td>
            <td>Nastava je izvođena po rasporedu, bez većih odstupanja</td>
            <td>{odgvor21.length}</td>
            {/* <td>{((odg1/brojStudenata)*100).toFixed(2)}</td> */}
            <td>{prosjekOdgovori2(odgvor21.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora2}({broj_studenata_ukupno})</td>
            {/* <td>{suma_odgovora2}</td> */}
            <td>{suma_procenata2}%</td>
          </tr>
       
        </tbody>
        {/* <tbody>
          <tr>
            <td>2.1</td>
            <td>Nastava je izvođena po rasporedu, bez većih odstupanja</td>
            <td>{odgvor21.length}</td>
            <td>{prosjekOdgovori2(odgvor21.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>2.2</td>
            <td>Nastava je izvođena po rasporedu, sa manjim odstupanjima</td>
            <td>{odgvor22.length}</td>
            <td>{prosjekOdgovori2(odgvor22.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>2.3</td>
            <td>Nastava je izvođena sa velikim odstupanjima od rasporeda</td>
            <td>{odgvor23.length}</td>
            <td>{prosjekOdgovori2(odgvor23.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>2.4</td>
            <td>Nastava nije izvođena po rasporedu</td>
            <td>{odgvor24.length}</td>
            <td>{prosjekOdgovori2(odgvor24.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora2}({broj_studenata_ukupno})</td>
            <td>{suma_procenata2.toFixed(2)}%</td>
          </tr>
       
        </tbody> */}

        {/* 3 odgovor */}
        <thead>
          <tr>
            <th>3</th>
            <th>Kvalitetom izvođenja nastave i uzajamnom povezanošću predavanja i vježbi sam:</th>
            {/* <th>Broj odgovora</th>
            <th>Procenat %{}</th> */}
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>3.1</td>
            <td>Nisam zadovoljna/an</td>
            <td>{odgvor34.length}</td>
            <td>{prosjekOdgovori3(odgvor34.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>3.2</td>
            <td>Djelomično zadovoljna/an</td>
            <td>{odgvor33.length}</td>
            <td>{prosjekOdgovori3(odgvor33.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>3.3</td>
            <td>Vrlo zadovoljna/an</td>
            <td>{odgvor31.length}</td>
            <td>{prosjekOdgovori3(odgvor31.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            {/* <td>{suma_odgovora3}</td> */}
            <td>{suma_odgovora3}({broj_studenata_ukupno})</td>
            <td>{suma_procenata3}%</td>
          </tr>
       
        </tbody>
        {/* <tbody>
          <tr>
            <td>3.1</td>
            <td>Vrlo zadovoljna/an</td>
            <td>{odgvor31.length}</td>
            <td>{prosjekOdgovori3(odgvor31.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>3.2</td>
            <td>Zadovoljana/an</td>
            <td>{odgvor32.length}</td>
            <td>{prosjekOdgovori3(odgvor32.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>3.3</td>
            <td>Djelomično zadovoljna/an</td>
            <td>{odgvor33.length}</td>
            <td>{prosjekOdgovori3(odgvor33.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>3.4</td>
            <td>Nisam zadovoljna/an</td>
            <td>{odgvor34.length}</td>
            <td>{prosjekOdgovori3(odgvor34.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora3}({broj_studenata_ukupno})</td>
            <td>{suma_procenata3.toFixed(2)}%</td>
          </tr>
       
        </tbody> */}
          {/* ODGOVOR 4 */}
        <thead>
          <tr>
            <th>4</th>
            <th>Kvalitetom komunikacije i interakcije između profesora-ice/docenta-ice/višeg-e asistenta-ice/asistenta-ice i studenata sam:</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>4.1</td>
            <td>Nisam zadovoljna/an</td>
            <td>{odgvor44.length}</td>
            <td>{prosjekOdgovori4(odgvor44.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>4.2</td>
            <td>Djelomično zadovoljna/an</td>
            <td>{odgvor43.length}</td>
            <td>{prosjekOdgovori4(odgvor43.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>4.3</td>
            <td>Vrlo zadovoljna/an</td>
            <td>{odgvor41.length}</td>
            {/* <td>{((odg1/brojStudenata)*100).toFixed(2)}</td> */}
            <td>{prosjekOdgovori4(odgvor41.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora4}({broj_studenata_ukupno})</td>
            <td>{suma_procenata4}%</td>
          </tr>
       
        </tbody>
        {/* <tbody>
          <tr>
            <td>4.1</td>
            <td>Vrlo zadovoljna/an</td>
            <td>{odgvor41.length}</td>
 
            <td>{prosjekOdgovori4(odgvor41.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>4.2</td>
            <td>Zadovoljana/an</td>
            <td>{odgvor42.length}</td>
            <td>{prosjekOdgovori4(odgvor42.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>4.3</td>
            <td>Djelomično zadovoljna/an</td>
            <td>{odgvor43.length}</td>
            <td>{prosjekOdgovori4(odgvor43.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>4.4</td>
            <td>Nisam zadovoljna/an</td>
            <td>{odgvor44.length}</td>
            <td>{prosjekOdgovori4(odgvor44.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>Molimo vas da ostavite dodatni komentar ili sugestiju:</td>
            <td>{odgvor45}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora4}({broj_studenata_ukupno})</td>
            <td>{suma_procenata4.toFixed(2)}%</td>
          </tr>
       
        </tbody> */}
        {/* ODGVOR 5 */}
        <thead>
          <tr>
            <th>5</th>
            <th>Da li sa profesorom-icom/docentom-icom/višim-om asistentom-icom/asistentom-icom možete ostvariti otvorenu komunikaciju, te iskazati svoj stav (čak i ako se razlikuje od stava nastavnika/saradnika)?</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>5.1</td>
            <td>Nikada</td>
            <td>{odgvor54.length}</td>
            <td>{prosjekOdgovori5(odgvor54.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>5.2</td>
            <td>Ponekad</td>
            <td>{odgvor53.length}</td>
            <td>{prosjekOdgovori5(odgvor53.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>5.3</td>
            <td>Uglavnom</td>
            <td>{odgvor52.length}</td>
            <td>{prosjekOdgovori5(odgvor52.length,broj_studenata_ukupno)}</td>
          </tr>
          {/* <tr>
            <td>5.3</td>
            <td>Ponekad</td>
            <td>{odgvor53.length}</td>
            <td>{prosjekOdgovori5(odgvor53.length,broj_studenata_ukupno)}</td>
          </tr> */}
         

          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora5}({broj_studenata_ukupno})</td>
            <td>{suma_procenata5.toFixed(2)}%</td>
            {/* <td>{suma_procenata5}%</td> */}
          </tr>
       
        </tbody>
        {/* <tbody>
          <tr>
            <td>5.1</td>
            <td>U potpunosti</td>
            <td>{odgvor51.length}</td>
            <td>{prosjekOdgovori5(odgvor51.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>5.2</td>
            <td>Uglavnom</td>
            <td>{odgvor52.length}</td>
            <td>{prosjekOdgovori5(odgvor52.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>5.3</td>
            <td>Ponekad</td>
            <td>{odgvor53.length}</td>
            <td>{prosjekOdgovori5(odgvor53.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>5.4</td>
            <td>Nikada</td>
            <td>{odgvor54.length}</td>
            <td>{prosjekOdgovori5(odgvor54.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>Molimo vas da ostavite dodatni komentar ili sugestiju:</td>
            <td>{odgvor55}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora5}({broj_studenata_ukupno})</td>
            <td>{suma_procenata5.toFixed(2)}%</td>
          </tr>
       
        </tbody> */}
         {/* ODGVOR 6 */}
         <thead>
          <tr>
            <th>6</th>
            <th> Da li osjećate vlastiti napredak radeći sa profesorom-icom/docentom-icom/višim-om asistentom-icom/asistentom-icom?</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>6.1</td>
            <td>Nikada</td>
            <td>{odgvor64.length}</td>
            <td>{prosjekOdgovori6(odgvor64.length,broj_studenata_ukupno)}</td>
          </tr>
          
          <tr>
            <td>6.2</td>
            <td>Ponekad</td>
            <td>{odgvor63.length}</td>
            <td>{prosjekOdgovori6(odgvor63.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>6.3</td>
            <td>Uglavnom</td>
            <td>{odgvor62.length}</td>
            <td>{prosjekOdgovori6(odgvor62.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora6}({broj_studenata_ukupno})</td>
            <td>{suma_procenata6}%</td>
          </tr>
       
        </tbody>
        {/* <tbody>
          <tr>
            <td>6.1</td>
            <td>U potpunosti</td>
            <td>{odgvor61.length}</td>
            <td>{prosjekOdgovori6(odgvor61.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>6.2</td>
            <td>Uglavnom</td>
            <td>{odgvor62.length}</td>
            <td>{prosjekOdgovori6(odgvor62.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>6.3</td>
            <td>Ponekad</td>
            <td>{odgvor63.length}</td>
            <td>{prosjekOdgovori6(odgvor63.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>6.4</td>
            <td>Nikada</td>
            <td>{odgvor64.length}</td>
            <td>{prosjekOdgovori6(odgvor64.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>Molimo vas da ostavite dodatni komentar ili sugestiju:</td>
            <td>{odgvor65}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora6}({broj_studenata_ukupno})</td>
            <td>{suma_procenata6.toFixed(2)}%</td>
          </tr>
       
        </tbody> */}
        {/* ODGVOR  7*/}
        <thead>
          <tr>
            <th>7</th>
            <th>Da li je profesor-ica/docent-ica/viši-a asistent-ica/asistent-ica dostupan/na za konsultacije koje su utvrđene rasporedom?</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>7.1</td>
            <td>Nikada</td>
            <td>{odgvor74.length}</td>
            <td>{prosjekOdgovori7(odgvor74.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>7.2</td>
            <td>Ponekad</td>
            <td>{odgvor73.length}</td>
            <td>{prosjekOdgovori7(odgvor73.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>7.3</td>
            <td>Uglavnom</td>
            <td>{odgvor72.length}</td>
            <td>{prosjekOdgovori7(odgvor72.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora7}({broj_studenata_ukupno})</td>
            <td>{suma_procenata7}%</td>
          </tr>
       
        </tbody>
        {/* <tbody>
          <tr>
            <td>7.1</td>
            <td>U potpunosti</td>
            <td>{odgvor71.length}</td>
            <td>{prosjekOdgovori7(odgvor71.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>7.2</td>
            <td>Uglavnom</td>
            <td>{odgvor72.length}</td>
            <td>{prosjekOdgovori7(odgvor72.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>7.3</td>
            <td>Ponekad</td>
            <td>{odgvor73.length}</td>
            <td>{prosjekOdgovori7(odgvor73.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>7.4</td>
            <td>Nikada</td>
            <td>{odgvor74.length}</td>
            <td>{prosjekOdgovori7(odgvor74.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora7}({broj_studenata_ukupno})</td>
            <td>{suma_procenata7.toFixed(2)}%</td>
          </tr>
       
        </tbody> */}
         {/* ODGVOR  8*/}
         <thead>
          <tr>
            <th>8</th>
            <th>Sylabuss predmeta i neophodni udžbenici i sredstva za rad:</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>8.1</td>
            <td>Nisu postojali i ne znam koji su</td>
            <td>{odgvor84.length}</td>
            <td>{prosjekOdgovori8(odgvor84.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>8.2</td>
            <td>Uglavnom su na raspolaganju (uz manje nedostatke)</td>
            <td>{odgvor82.length}</td>
            <td>{prosjekOdgovori8(odgvor82.length,broj_studenata_ukupno)}</td>
          </tr>


          <tr>
            <td>8.3</td>
            <td>Potpuno su i stalno dostupni</td>
            <td>{odgvor81.length}</td>
            {/* <td>{((odg1/brojStudenata)*100).toFixed(2)}</td> */}
            <td>{prosjekOdgovori8(odgvor81.length,broj_studenata_ukupno)}</td>
          </tr>
          
         
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora8}({broj_studenata_ukupno})</td>
            <td>{suma_procenata8}%</td>
          </tr>
       
        </tbody>
        {/* <tbody>
          <tr>
            <td>8.1</td>
            <td>Potpuno su i stalno dostupni</td>
            <td>{odgvor81.length}</td>
            <td>{prosjekOdgovori8(odgvor81.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>8.2</td>
            <td>Uglavnom su na raspolaganju (uz manje nedostatke)</td>
            <td>{odgvor82.length}</td>
            <td>{prosjekOdgovori8(odgvor82.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>8.3</td>
            <td>Postoje, ali su uglavnom nedostupni</td>
            <td>{odgvor83.length}</td>
            <td>{prosjekOdgovori8(odgvor83.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>8.4</td>
            <td>Nisu postojali i ne znam koji su</td>
            <td>{odgvor84.length}</td>
            <td>{prosjekOdgovori8(odgvor84.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora8}({broj_studenata_ukupno})</td>
            <td>{suma_procenata8.toFixed(2)}%</td>
          </tr>
       
        </tbody> */}
         {/* ODGVOR  9*/}
        <thead>
          <tr>
            <th>9</th>
            <th>Plan i program predmeta je:</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>9.1</td>
            <td>Nikada</td>
            <td>{odgvor115.length}</td>
            <td>{prosjekOdgovori11(odgvor115.length,broj_studenata_ukupno)}</td>
          </tr>
         
          <tr>
            <td>9.2</td>
            <td>Ponekad</td>
            <td>{odgvor114.length}</td>
            <td>{prosjekOdgovori11(odgvor114.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>9.3</td>
            <td>Uglavnom</td>
            <td>{odgvor113.length}</td>
            <td>{prosjekOdgovori11(odgvor113.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora9}({broj_studenata_ukupno})</td>
            <td>{suma_procenata9.toFixed(2)}%</td>
          </tr>
       
        </tbody>

        {/* <tbody>
          <tr>
            <td>9.1</td>
            <td>U dobroj mjeri opterećenja i obima</td>
            <td>{odgvor91.length}</td>
            <td>{prosjekOdgovori9(odgvor91.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>9.2</td>
            <td>Mogao bi biti manje obiman</td>
            <td>{odgvor92.length}</td>
            <td>{prosjekOdgovori9(odgvor92.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>9.3</td>
            <td>Mogao bi biti više obiman</td>
            <td>{odgvor93.length}</td>
            <td>{prosjekOdgovori9(odgvor93.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>9.4</td>
            <td>Mogao bi biti kompleksniji</td>
            <td>{odgvor94.length}</td>
            <td>{prosjekOdgovori9(odgvor94.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>9.5</td>
            <td>Mogao bi biti jednostavniji</td>
            <td>{odgvor95.length}</td>
            <td>{prosjekOdgovori9(odgvor95.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>9.6</td>
            <td>Previše je obiman i težak</td>
            <td>{odgvor96.length}</td>
            <td>{prosjekOdgovori9(odgvor96.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora9}({broj_studenata_ukupno})</td>
            <td>{suma_procenata9.toFixed(2)}%</td>
          </tr>
       
        </tbody> */}
        {/* ODGVOR  10*/}
        <thead>
          <tr>
            <th>10</th>
            <th>Potrebno mi je: (možete označiti više od jednog odgovora, ako se nadopunju, tj. nisu u kontradikciji)</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>10.1</td>
            <td>Nikada</td>
            <td>{odgvor125.length}</td>
            <td>{prosjekOdgovori12(odgvor125.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>10.2</td>
            <td>Ponekad</td>
            <td>{odgvor124.length}</td>
            <td>{prosjekOdgovori12(odgvor124.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>10.3</td>
            <td>Uglavnom</td>
            <td>{odgvor123.length}</td>
            <td>{prosjekOdgovori12(odgvor123.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora10}</td>
          <td>{suma_procenata12}%</td>
          </tr>
       
        </tbody>
        {/* <tbody>
          <tr>
            <td>10.1</td>
            <td>Više vježbi</td>
            <td>{odgvor101.length}</td>
            <td>{prosjekOdgovori10(duzinaOdg1[0],broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>10.2</td>
            <td>Više predavanja</td>
            <td>{odgvor102.length}</td>
            <td>{prosjekOdgovori10(duzinaOdg2[0],broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>10.3</td>
            <td>Više vremena za pripremanje praktičnih zadataka</td>
            <td>{odgvor103.length}</td>
            <td>{prosjekOdgovori10(duzinaOdg3[0],broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>10.4</td>
            <td>Nemam definisano osjećanje ili stav o ovom pitanju</td>
            <td>{odgvor104.length}</td>
            <td>{prosjekOdgovori10(duzinaOdg4[0],broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora10}({broj_studenata_ukupno})</td>
            <td>{suma_procenata10.toFixed(2)}%</td>
          </tr>
       
        </tbody> */}
        {/* ODGVOR  11*/}
        <thead>
          <tr>
            <th>11</th>
            <th>Da li su vam bili jasno predstavljeni vaši zadaci i obaveze u procesu nastave?</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>11.1</td>
            <td>Nikada</td>
            <td>{odgvor96.length}</td>
            <td>{prosjekOdgovori111(odgvor96.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>11.2</td>
            <td>Ponekad</td>
            <td>{odgvor95.length}</td>
            <td>{prosjekOdgovori111(odgvor95.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>11.3</td>
            <td>Uglavnom</td>
            <td>{odgvor94.length}</td>
            <td>{prosjekOdgovori111(odgvor94.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td>{suma_odgovora11}</td>
            {/* <td>{suma_procenata11}%</td> */}
            <td>{suma_procenata111.toFixed(2)}%</td>
          </tr>
       
        </tbody>
        {/* <tbody>
          <tr>
            <td>11.1</td>
            <td>U potpunosti</td>
            <td>{odgvor111.length}</td>
            <td>{prosjekOdgovori11(odgvor111.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>11.2</td>
            <td>U većini slučajeva</td>
            <td>{odgvor112.length}</td>
            <td>{prosjekOdgovori11(odgvor112.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>11.3</td>
            <td>Uglavnom</td>
            <td>{odgvor113.length}</td>
            <td>{prosjekOdgovori11(odgvor113.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>11.4</td>
            <td>Ponekad</td>
            <td>{odgvor114.length}</td>
            <td>{prosjekOdgovori11(odgvor114.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>11.5</td>
            <td>Nikada</td>
            <td>{odgvor115.length}</td>
            <td>{prosjekOdgovori11(odgvor115.length,broj_studenata_ukupno)}</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>

            <td>{suma_procenata11.toFixed(2)}%</td>
          </tr>
       
        </tbody> */}
         {/* ODGVOR  12*/}
         {/* <thead>
          <tr>
            <th>12</th>
            <th>Da li vam je jasan i transparentan kriterij na osnovu kojeg profesor procjenjuje rad studenata?</th>
          </tr>
        </thead> */}
     
                 {/* ODGVOR  13*/}
                 <thead>
          <tr>
            <th>12</th>
            <th>Molimo vas da na kraju ostavite dodatni komentar ili sugestiju:(npr. prijedlog unaprijedjenja izvodjenja nastave)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>-</td>
            <td>{odgvorPitanje13}</td>
          </tr>
   
       
        </tbody>
      </Table>

  <br/>
    <br/>
  </Form>
    </>
  );
}

export default TemplateOdgovoraUserDetalj;