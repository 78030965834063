import "./productList.css";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Badge from 'react-bootstrap/Badge';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import Url from '../api/defUrl';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {Alert } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

export default function PollList() {
  let [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [nastavniciData, setNastavniciData] = useState([]);
  const [odsjeciData, setOdsjeciData] = useState([]);
  const [predmetiData, setPredmetiData] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isSubmittedData, setIsSubmitteData] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const userId = localStorage.getItem('userId');
// console.log(data)
  useEffect(() => {
    setLoading(true);
    axios.get(`${Url}/api/anketa/`)
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(err => console.log(err));

    // axios.get(`${Url}/api/odsjeci/`)
    //   .then(res => {
    //     setOdsjeciData(res.data);
    //   })
    //   .catch(err => console.log(err));
    setLoading(true);
    axios.get(`${Url}/api/predmeti/`)
      .then(res => {
        setPredmetiData(res.data);
        setLoading(false);
      })
      .catch(err => console.log(err));

    // axios.get(`${Url}/api/nastavnici/`)
    //   .then(res => {
    //     setNastavniciData(res.data);
    //   })
    //   .catch(err => console.log(err));
  }, []);

  const handleDelete = (id) => {
    // const trazenaAnketa = data.find((anketa) => anketa._id === id);
    // Prikazati dijalog za potvrdu
    const confirmDelete = window.confirm('Da li ste sigurni da želite izbrisati ovu anketu?');

    if (confirmDelete) {
        // Korisnik je potvrdio brisanje, izvršite brisanje
        setLoading(true);

        axios
            .delete(`${Url}/api/anketa/${id}`)
            .then((response) => {
                // console.log(response);
                const filtriraniData = data.filter((anketa) => anketa._id !== id);
                setData(filtriraniData);
                setIsSubmitted(true);
                setLoading(false);

                // Dodajte logiku za ažuriranje vašeg lokalnog stanja ili ponovno učitavanje anketa ako je potrebno
            })
            .catch((error) => {
                isSubmittedData(true)
                console.error('Došlo je do greške prilikom brisanja ankete:', error);
                // Dodajte logiku za upravljanje greškama ako je potrebno
            });
    } else {
        // Korisnik je odbio brisanje, ništa se neće izbrisati
        console.log('Brisanje ankete otkazano');
    }
};


  const filterData = () => {
    let filteredData = data;

    if (selectedSubject) {
      filteredData = filteredData.filter((row) =>
        row.predmet.some((subject) => subject.nazivPredmeta === selectedSubject)
      );
    }

    if (searchText) {
      filteredData = filteredData.filter((row) =>
        row.nastavnik.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return filteredData;
  };

  const columns = [
    {
      field: "actions", // Polje za akcije (uređivanje i brisanje)
      headerName: "Akcije",
      width: 120,
      renderCell: (params) => {
        const ankatId = params.row._id;
        return (
          <div className="productListItem">
             <Link to={`/anketa/${ankatId}`}>
              <button className="viewButton">
                <RemoveRedEyeIcon />
              </button>
            </Link>
            {/* {params.row.kreirao === userId ?
            <> */}
            <Link to={`/uredi-anketu/${ankatId}`}>
              <button className="editButton">
                <EditIcon />
              </button>
            </Link>
            
            <button
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}
            >
              <DeleteIcon />
            </button>
            {/* </>
            :""
      } */}
          </div>
        );
      },
    },
    
    {
      field: "nastavnik",
      headerName: "Nastavnik",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.nastvanik.map((e)=> <p>{e.ime} {e.prezime}</p>)}
          </div>
        );
      },
    },
    {
      field: "predmet",
      headerName: "Predmet",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.predmet.map((e)=> <p>{e.nazivPredmeta}</p>)}
          </div>
        );
      },
    },
    {
      field: "pitanje1",
      headerName: "1",
      width: 82,
      renderCell: (params) => {
        // console.log(typeof(params.row.pitanje1))
        return (
          <div className="productListItem">
            {params.row.pitanje1 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje2",
      headerName: "2",
      width: 82,
      renderCell: (params) => {
        // console.log(typeof(params.row.pitanje2))
        return (
          <div className="productListItem">
            {params.row.pitanje2 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje3",
      headerName: "3",
      width: 82,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.pitanje3 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje4",
      headerName: "4",
      width: 82,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.pitanje4.pitanje !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje5",
      headerName: "5",
      width: 82,
      renderCell: (params) => {
        // console.log(params.row.pitanje5.pitanje)
        return (
          <div className="productListItem">
            {params.row.pitanje5.pitanje !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje6",
      headerName: "6",
      width: 82,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.pitanje6.pitanje !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje7",
      headerName: "7",
      width: 82,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.pitanje7 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje8",
      headerName: "8",
      width: 82,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.pitanje8 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje9",
      headerName: "9",
      width: 82,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.pitanje9 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje10",
      headerName: "10",
      width: 100,
      renderCell: (params) => {
        const parametar = params.row.pitanje10
        // console.log(parametar.option1 || parametar.option2 || parametar.option3 || parametar.option4)
        return (
          <div className="productListItem">
            {parametar.option1 || parametar.option2 || parametar.option3 || parametar.option4 !== false ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje11",
      headerName: "11",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.pitanje11 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje12",
      headerName: "12",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.pitanje12.pitanje !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje13",
      headerName: "13",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.pitanje13 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
  ];
  if (loading) {
    return (
        <div style={{ display: 'flex', marginLeft:"30%",justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ClipLoader color="#36D7B7" loading={loading} size={150} />
      </div>
    );
  }
  return (
    <div className="productList">
         {
        isSubmitted ? (
          <Alert variant="success" style={{ width: "42rem" }}>
            <Alert.Heading>Anketa je uspjesno obrisana</Alert.Heading>
          </Alert>
        ) : (
          isSubmittedData ? (
            <Alert variant="danger" style={{ width: "42rem" }}>
              <Alert.Heading>Kreska prilikom brisanja ankete</Alert.Heading>
            </Alert>
          ) : null // Null se koristi ako ne želite prikazati poruku ako nije isSubmitted ni isSubmittedData
        )
      }
      <Link to={"/anketa-nova"}>
        <button className="productListEdit">Nova anketa</button>
      </Link>
      <div className="filters">
        <Autocomplete
          options={predmetiData.map((predmet) => predmet.nazivPredmeta)}
          getOptionLabel={(option) => option}
          value={selectedSubject}
          onChange={(event, newValue) => {
            setSelectedSubject(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Odaberite predmet" variant="outlined" />
          )}
        />
        {/* <TextField
          label="Pretraga"
          variant="outlined"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        /> */}
      </div>
      <DataGrid
        rows={filterData()}
        disableSelectionOnClick
        columns={columns}
        getRowId={(row) => row._id}
        pageSize={16}
        headerHeight={65}
        rowHeight={45}
      />
    </div>
  );
}

