import "../userList/userList.css";
// import { DataGrid } from "@material-ui/data-grid";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { userRows } from "../../dummyData";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import Url from '../api/defUrl'
import Button from '@mui/material/Button';
import ClipLoader from "react-spinners/ClipLoader";
import { TextField } from "@mui/material";

export default function SviPredmeti24() {
  const [data, setData] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  let [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
 
//   console.log(data)
  useEffect(() => {
    setLoading(true);
    axios.get(`${Url}/api/predmeti/`)
      .then(res => {
        // Filtriraj predmete prema semestru
        const filtriraniPredmeti = res.data.filter(
          (predmet) => predmet.semestar !== "zimski"
        );
        
        setData(filtriraniPredmeti);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);
useEffect(() => {
    
    // Filtriraj predmete prema traženom pojmu
    // const filtriraniPredmetiSaPretragom = data.filter(
    //   (predmet) => predmet.nazivPredmeta.toLowerCase().includes(searchTerm.toLowerCase())
    // );

     // Filtriraj predmete prema traženom pojmu (naziv ili šifra predmeta)
     const filtriraniPredmetiSaPretragom = data.filter(
        (predmet) => {
          const nazivMatch = predmet.nazivPredmeta.toLowerCase().includes(searchTerm.toLowerCase());
          const sifraMatch = predmet.sifraPredmeta.toLowerCase().includes(searchTerm.toLowerCase());
          return nazivMatch || sifraMatch;
        }
      );
    
    setFilterData(filtriraniPredmetiSaPretragom);

}, [data, searchTerm]);
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${Url}/api/predmeti/${id}`);
  
      if (response.status === 200) {
        alert(`Predmet sa ID ${id} je uspješno obrisan.`)
        // console.log(`Predmet sa ID ${id} je uspješno obrisan.`);
        // Osvježite podatke nakon brisanja ako je potrebno
        // setData(data?.filter((item) => item.id !== id));
      } else {
        alert(`Greška prilikom brisanja predmeta sa ID`)
        console.error(`Greška prilikom brisanja predmeta sa ID ${id}.`);
      }
    } catch (error) {
      console.error('Dogodila se pogreška prilikom izvršavanja delete metode:', error);
    }
  };
  
  const columns = [
    // { field: "_id", headerName: "ID", width: 90 },
    { field: "sifraPredmeta", headerName: "Šifra", width: 110 },
    {
        field: "nazivPredmeta",
        headerName: "Naziv predmeta",
        width: 300,
        renderCell: (params) => {
          return (
            <div className="userListUser">
         
              <p>
                <Link to={`/predmetd/${params.row._id}/detalj/24`}>{`${params.row?.nazivPredmeta}`}</Link>
                </p>
            </div>
          );
        },
      },
    {
      field: "odsjek",
      headerName: "Odsjek",
      width: 200,
      renderCell: (params) => {
        const odsjek = params.row.odsjek
        
        return (
          <div className="userListUser">
            {odsjek.map((e)=>{
          return e.nazivOdsjeka
          })}
      
          </div>
        );
      },
    },
    { field: "brojStudenata", headerName: "Br. stud.", width: 100 },
    {
      field: "nastavnik",
      headerName: "Nastavnik",
      width: 200,
      renderCell: (params) => {
        const nastavnik = params.row.nastavnik
        // console.log(params.row.nastavnik)
        return (

          <div className="userListUser">
            {/* <p>{params.row.nastavnik}</p> */}
            {nastavnik?.map((e)=>{
          return <>{e?.ime} {e?.prezime}</>
          })}
       
          </div>
        );
      },
    },
    {
      field: "anketa",
      headerName: "Ankete",
      width: 70,
      renderCell: (params) => {
        // const odsjek = params.row.odsjek
        // console.log(params?.row?.anketa)
        return (
          <div className="userListUser">
            <p>{params?.row?.anketa?.length}</p>
    
          </div>
        );
      },
    },
    {
        field: "kood",
        headerName: "Kod",
        width: 50,
        renderCell: (params) => {
          // const odsjek = params.row.odsjek
          // console.log(params?.row?.anketa)
          return (
            <div className="userListUser">
              <p>{params?.row?.kood?.length}</p>
            </div>
          );
        },
      },
    
      {
        field: "delete",
        headerName: "Brisanje",
        width: 100,
        renderCell: (params) => {
            // console.log(params?.row)
          return (
            <div className="userListUser">
              <Button
                variant="contained"
                color="warning"
                style={{height:"30px"}}
                onClick={() => handleDelete(params.row._id)}
                >
      Briši
    </Button>
            </div>
          );
        },
      },

  ];
  function getRowId(row) {
    return row?._id; // Replace with your unique id property
  }

  if (loading) {
    return (
        <div style={{ display: 'flex', marginLeft:"30%",justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ClipLoader color="#36D7B7" loading={loading} size={150} />
      </div>
    );
  }
  return (
    <div className="userList">
         <TextField
        label="Pretraga predmeta"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />

       <Link to={"/predmet-novi"}>
       <button className="productListEdit">Novi predmet</button>
       </Link>
      <DataGrid
        rows={filterdata}
        disableSelectionOnClick
        columns={columns}
        pageSize={16}
        getRowId={getRowId}
        // getRowId={(row) => row._id}
        // checkboxSelection
      />
    </div>
  );
}
