import React, { createContext, useState,useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userId, setUserId] = useState('');
    const [role, setRole] = useState('');
  
    useEffect(() => {
      // Dohvati userId i role iz localStorage prilikom prvog renderiranja
      const storedUserId = localStorage.getItem('userId');
      const storedRole = localStorage.getItem('role');
  
      // Postavi userId i role u lokalno stanje komponente
      if (storedUserId) {
        setUserId(storedUserId);
      }
      if (storedRole) {
        setRole(storedRole);
      }
    }, []);
  
    const updateUser = (userId, role) => {
      // Ažuriraj userId i role i spremi ih u localStorage
      setUserId(userId);
      setRole(role);
      localStorage.setItem('userId', userId);
      localStorage.setItem('role', role);
    };

  return (
    <UserContext.Provider value={{ userId, role, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};
