import { useState, createContext, useContext } from "react";
import { AnketaContext } from "../context/ContextComponent";
import { Form, Button, Col } from "react-bootstrap";
import BirajNastavnikaPredmetIzvjestaj from "./BirajNastanikaPredmetIzvjestaj";
import FeaturedInfo from "../featuredInfo/FeaturedInfo";
// import BirajNastavnikaPredmet from './birajNastavnikaPredmet';
// import BirajNastavnikaOdsjek from './birajOdsjekPredmet';
// import { useContext,createContext } from "react";



const BirajNastavnikaIzvjestaj = () =>{

   
    const {nastavniciData, setNastavnikAnketa} = useContext(AnketaContext);

    // console.log(nastavniciData)
    const [predmetNastavnik, setPredmetNastavnik] = useState([])
    const [predmetNastavnikID, setPredmetNastavnikID] = useState([])

    // console.log("predmetNastavnikID", nastavniciData)

    const handleSelectChange = (e)=>{
        // console.log("dddd",e.target.value)
        const myArray = e.target.value.split(",");
        let rows = []
        let columns = []
        let nastavik = []
        let d =[]
        let y = []

        myArray.map((el)=>{
            let x = el.split(":")
            // console.log(x)
            if(x[0]=="predmetNaziv"){
                rows.push(x[1])
                // y.push("naziv:"+x[1])
            } else if(x[0]=="predmetID"){
                columns.push(x[1])
                // y.push("predmetID:"+x[1])
            } else if(x[0]== "nastavnikID"){
                nastavik.push(x[1])
                setPredmetNastavnikID(x[1])
            } else if(x[0]== "odsjekID"){
                d.push(x[1])
                // y.push("Odsjek:"+ x[1])
            }
        })
//  console.log("ovo je xc: ",c);
        let predmetI = []
        nastavniciData.map((nast)=>{
            if(nast._id == nastavik){
                predmetI = nast.predmet
                // console.log("ovo je xc: ",c);
            }
          })
        const result = rows.reduce((acc, cur, idx) => {
            acc[cur] = columns[idx];
            return acc;
          }, {});

        setPredmetNastavnik(predmetI)
        setNastavnikAnketa(nastavik)
        // console.log(nastavik)
    }

 
//    console.log(predmetNastavnikID)
    let list = nastavniciData?.map((e)=>{
        return (
        <>
            <option value={`nastavnikID:${e._id},${e?.predmet?.map((e) => `predmetID:${e._id},predmetNaziv:${e.nazivPredmeta},odsjekID:${e.odsjek.map((e)=>e)}`)}`}>{e.ime} {e.prezime}</option>
        </>
        )
        })
    // console.log(data.data)
    return(
        <> 
     {/* <FeaturedInfo/> */}
          {/* <div className="widgetsm" style={{width:"36%"}}> */}
          <Form.Label className="fw-bold">Odaberi nastavnika:</Form.Label>
    <Form.Control as="select" 
    onChange={handleSelectChange}
    >
     <option value="none" selected disabled hidden> </option> 
        {list}
    </Form.Control>
   <BirajNastavnikaPredmetIzvjestaj data={predmetNastavnik} datab={predmetNastavnikID}/>
    {/* </div>  */}
        </>
    )
}

export default BirajNastavnikaIzvjestaj;