import React, {useState} from 'react'
import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import "./App.css";
import Home from "./pages/home/Home";
import { HashRouter as Router, Switch, Route , Redirect} from "react-router-dom";
import UserList from "./pages/userList/UserList";
import User from "./pages/user/User";
import NewNastavnik from "./pages/newUser/NewUser";
import PollList from "./pages/pollList/PollList";
import Poll from "./pages/poll/Poll";
// import NewProduct from "./pages/newProduct/NewProduct";
import NewPoll from "./pages/newPoll/NewPoll";
import OdsjeciAll from "./pages/department/allDepartments";
import NewDepartment from "./pages/department/NewDepartment";
import SviPredmeti from "./pages/predmeti/sviPredmeti";
import NoviPredmet from "./pages/predmeti/noviPremet";
import Izvjestaj from "./pages/izvjestaji/Izvjestaj";
import InzvjestajASU from "./pages/izvjestaji/IzvjestaASU";
import InzvjestajOdsjek from "./pages/izvjestaji/izvjestajOdsjek";
import { UserProvider } from './components/context/UserContext';

// import { Route, Redirect } from 'react-router-dom';

import Login from './pages/auth/Login';
import NastavnikDetalj from './pages/user/userDetail';
import PollListUser from './pages/pollList/PollListUser';
import TemplateDetaljAnkete from './pages/poll/PoolView';
import SviPredmetiUser from './pages/predmeti/sviPredmetiUser';
import Registracija from './pages/auth/Registracija';
import EditPoll from './pages/poll/PoolEdit';
import InzvjestajOdsjek2 from './pages/izvjestaji/izvjestajOdsjek2';
import AnketaStudent from './pages/studenti/anketa';
import PredmetNastavnik from './pages/predmeti/predmetNastavnik';
import Nastavnici2024 from './pages/userList/nastavnici2024';
import NastavnikPredmetDetalj from './pages/user/nastavnikPredmetDetalj';
import SviPredmeti24 from './pages/predmeti/predmeti24';
import DetaljPredmeta24 from './pages/predmeti/detaljPredmeta24';
import NoviKood from './pages/NoviKood';
import PredmetKood from './pages/PredmetKood';
import Kodovi from './pages/Kodovi';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = localStorage.getItem('token');
  const userId = localStorage.getItem('userId')

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <UserProvider>
    <Router>
      <Route path="/studenti-anketa">
            <AnketaStudent/>
          </Route>
      <Topbar />
      <div className="container">

      {user !== null ? <Sidebar /> : ""}
        <Switch>
        <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/registracija">
              <Registracija />
            </Route>
          
        {user !== null ? 
        <Route 
        exact path="/">
          <Home userId={userId}/>
          </Route> 
          : <Login />
          }
          {user !== null ?
          <Route path="/nastavnici2024/:id/predmeti/detalj">
            <NastavnikPredmetDetalj />
          </Route>
          : <Login />}
          {user !== null ? <Route path="/odsjeci"><OdsjeciAll /></Route> : <Login />}
         
          {user !== null ? <Route path="/odsjek-novi">
            <NewDepartment />
          </Route>
          : <Login />}
          {user !== null ? <Route path="/nastavnik/predmeti/:id">
            <SviPredmetiUser />
          </Route>
          : <Login />}
          {user !== null ? <Route path="/predmeti">
            <SviPredmeti />
          </Route>
          : <Login />}

        {user !== null ? 
        <Route path="/predmet/kood">
            <PredmetKood />
          </Route>
          : <Login />}
        {user !== null ? 
        <Route path="/kood/svi/">
            <Kodovi />
          </Route>
          : <Login />}
        {user !== null ? 
        <Route path="/kood/novi/">
            <NoviKood />
          </Route>
          : <Login />}
      
          {user !== null ? 
          <Route path="/predmet-novi">
            <NoviPredmet />
          </Route>
            : <Login />}
            {user !== null ?
          <Route path="/nastavnici">
            <UserList />
          </Route>
          : <Login />}
     {user !== null ?
          <Route path="/nastavnici2024">
            <Nastavnici2024 />
          </Route>
          : <Login />}
 {user !== null ?
          <Route path="/predmeti2024">
            <SviPredmeti24 />
          </Route>
          : <Login />} 
          {user !== null ?
          <Route path="/predmetd/:id/detalj/24">
            <DetaljPredmeta24 />
          </Route>
          : <Login />}          
          {user !== null ? 
            <Route path="/nastavnik/:id/ankete">
              <NastavnikDetalj />
            </Route>
            : <Login />} 
          {user !== null ? 
          <Route path="/nastavnik-novi">
            <NewNastavnik />
          </Route>
          : <Login />}
          {user !== null ? 
             <Route path="/predmet-nastavnik">
             <PredmetNastavnik />
           </Route>
          : <Login />}
        
          {user !== null ?
          <Route path="/ankete">
            <PollList />
          </Route>
          : <Login />}
          {user !== null ?
          <Route path="/anketa/:id">
            <TemplateDetaljAnkete />
          </Route>
          : <Login />}
           {user !== null ?
          <Route path="/uredi-anketu/:id">
            <EditPoll />
          </Route>
          : <Login />}
          {user !== null ?
          <Route exec path="/nastavnik/ankete/:id">
            <PollListUser />
          </Route>
          : <Login />}
          {user !== null ?
          <Route path="/product/:productId">
            <Poll />
          </Route>
          : <Login />}
          {user !== null ?
          <Route path="/anketa-nova">
            <NewPoll />
          </Route>
          : <Login />}
 
          {user !== null ?
          <>
          <Route path="/izvjestaji">
            <Izvjestaj/>
          </Route>
          <Route path="/odsjek/izvjestaji">
            <InzvjestajOdsjek/>
          </Route>
          <Route path="/asu/izvjestaji">
  
            <InzvjestajOdsjek2/>
          </Route>
          </>
          : <Login />}
           
        </Switch>
      </div>
    </Router>
    </UserProvider>
  );
}

export default App;



// import React, { useState, useEffect } from 'react';
// import Sidebar from "./components/sidebar/Sidebar";
// import Topbar from "./components/topbar/Topbar";
// import "./App.css";
// import Home from "./pages/home/Home";
// import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
// import UserList from "./pages/userList/UserList";
// import User from "./pages/user/User";
// import NewNastavnik from "./pages/newUser/NewUser";
// import PollList from "./pages/pollList/PollList";
// import Poll from "./pages/poll/Poll";
// import NewPoll from "./pages/newPoll/NewPoll";
// import OdsjeciAll from "./pages/department/allDepartments";
// import NewDepartment from "./pages/department/NewDepartment";
// import SviPredmeti from "./pages/predmeti/sviPredmeti";
// import NoviPredmet from "./pages/predmeti/noviPremet";
// import Izvjestaj from "./pages/izvjestaji/Izvjestaj";
// import InzvjestajASU from "./pages/izvjestaji/IzvjestaASU";
// import InzvjestajOdsjek from "./pages/izvjestaji/izvjestajOdsjek";
// import { UserProvider } from './components/context/UserContext';
// import Login from './pages/auth/Login';
// import NastavnikDetalj from './pages/user/userDetail';
// import PollListUser from './pages/pollList/PollListUser';
// import TemplateDetaljAnkete from './pages/poll/PoolView';
// import SviPredmetiUser from './pages/predmeti/sviPredmetiUser';
// import Registracija from './pages/auth/Registracija';
// import EditPoll from './pages/poll/PoolEdit';
// import InzvjestajOdsjek2 from './pages/izvjestaji/izvjestajOdsjek2';
// import AnketaStudent from './pages/studenti/anketa';
// import PredmetNastavnik from './pages/predmeti/predmetNastavnik';
// import Nastavnici2024 from './pages/userList/nastavnici2024';
// import NastavnikPredmetDetalj from './pages/user/nastavnikPredmetDetalj';
// import SviPredmeti24 from './pages/predmeti/predmeti24';
// import DetaljPredmeta24 from './pages/predmeti/detaljPredmeta24';
// import NoviKood from './pages/NoviKood';
// import PredmetKood from './pages/PredmetKood';
// import LoginKood from './pages/auth/Login1';

// function App() {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [currentRoute, setCurrentRoute] = useState(window.location.hash);
//   // console.log(currentRoute)
//   const user = localStorage.getItem('token');
//   const userId = localStorage.getItem('userId');
//   // console.log(user)
//   useEffect(() => {
//     const handleRouteChange = () => {
//       setCurrentRoute(window.location.pathname);
//     };

//     // Dodajemo event listener za praćenje promjena rute
//     window.addEventListener('hashchange', handleRouteChange);

//     return () => {
//       // Čistimo event listener kada komponenta unmounts
//       window.removeEventListener('hashchange', handleRouteChange);
//     };
//   }, []);

//   const handleLogin = () => {
//     setIsLoggedIn(true);
//   };

//   const handleLogout = () => {
//     setIsLoggedIn(false);
//   };

//   const isStudentiAnketaRoute = currentRoute === "#/studenti-anketa";
//   const kodExists = localStorage.getItem('kood') !== null;

//   // console.log(currentRoute)
//   // console.log(isStudentiAnketaRoute)
// // console.log(kodExists)
//   return (
//     <UserProvider>
//       <Router>
//         {/* Ako je ruta /studenti-anketa i nema koda, redirectaj na LoginKood */}
//         {isStudentiAnketaRoute && !kodExists ? <LoginKood /> : 
//          <Route path="/studenti-anketa">
//          <AnketaStudent/>
//        </Route>
//         }
//         {/* Ako je ruta bilo koja osim /studenti-anketa, ili ako postoji kod, prikaži Login */}
//         {/* {user !== null && !isStudentiAnketaRoute ? <Login /> : null} */}
//         {user !== null && !isStudentiAnketaRoute ? <Home userId={userId}/> : 
//          <Route path="/login">
//          <Login/>
//        </Route>
//         }
//         <Topbar />
//         <div className="container">
//           {user !== null && !isStudentiAnketaRoute ? <Sidebar /> : ""}
//           <Switch>
//           {/* <Route path="/studenti-anketa">
//             <AnketaStudent/>
//           </Route> */}
//       <Topbar />
//       <div className="container">

//       {user !== null ? <Sidebar /> : ""}
//         <Switch>
//         <Route exact path="/login">
//               <Login />
//             </Route>
//             <Route exact path="/registracija">
//               <Registracija />
//             </Route>
          
//         {/* {user !== null ? 
//         <Route  exact path="/">
//           <Home userId={userId}/>
//           </Route> 
//           : <Login />
//           }
//           {user !== null ?
//           <Route path="/nastavnici2024/:id/predmeti/detalj">
//             <NastavnikPredmetDetalj />
//           </Route>
//           : <Login />}
//           {user !== null ? <Route path="/odsjeci"><OdsjeciAll /></Route> : <Login />}
         
//           {user !== null ? <Route path="/odsjek-novi">
//             <NewDepartment />
//           </Route>
//           : <Login />}
//           {user !== null ? <Route path="/nastavnik/predmeti/:id">
//             <SviPredmetiUser />
//           </Route>
//           : <Login />}
//           {user !== null ? <Route path="/predmeti">
//             <SviPredmeti />
//           </Route>
//           : <Login />}

//         {user !== null ? 
//         <Route path="/predmet/kood">
//             <PredmetKood />
//           </Route>
//           : <Login />}
//         {user !== null ? 
//         <Route path="/kood/novi">
//             <NoviKood />
//           </Route>
//           : <Login />}
      
//           {user !== null ? 
//           <Route path="/predmet-novi">
//             <NoviPredmet />
//           </Route>
//             : <Login />}
//             {user !== null ?
//           <Route path="/nastavnici">
//             <UserList />
//           </Route>
//           : <Login />}
//      {user !== null ?
//           <Route path="/nastavnici2024">
//             <Nastavnici2024 />
//           </Route>
//           : <Login />}
//  {user !== null ?
//           <Route path="/predmeti2024">
//             <SviPredmeti24 />
//           </Route>
//           : <Login />} 
//           {user !== null ?
//           <Route path="/predmetd/:id/detalj/24">
//             <DetaljPredmeta24 />
//           </Route>
//           : <Login />}          
//           {user !== null ? 
//             <Route path="/nastavnik/:id/ankete">
//               <NastavnikDetalj />
//             </Route>
//             : <Login />} 
//           {user !== null ? 
//           <Route path="/nastavnik-novi">
//             <NewNastavnik />
//           </Route>
//           : <Login />}
//           {user !== null ? 
//              <Route path="/predmet-nastavnik">
//              <PredmetNastavnik />
//            </Route>
//           : <Login />}
        
//           {user !== null ?
//           <Route path="/ankete">
//             <PollList />
//           </Route>
//           : <Login />}
//           {user !== null ?
//           <Route path="/anketa/:id">
//             <TemplateDetaljAnkete />
//           </Route>
//           : <Login />}
//            {user !== null ?
//           <Route path="/uredi-anketu/:id">
//             <EditPoll />
//           </Route>
//           : <Login />}
//           {user !== null ?
//           <Route exec path="/nastavnik/ankete/:id">
//             <PollListUser />
//           </Route>
//           : <Login />}
//           {user !== null ?
//           <Route path="/product/:productId">
//             <Poll />
//           </Route>
//           : <Login />}
//           {user !== null ?
//           <Route path="/anketa-nova">
//             <NewPoll />
//           </Route>
//           : <Login />}
 
//           {user !== null ?
//           <>
//           <Route path="/izvjestaji">
//             <Izvjestaj/>
//           </Route>
//           <Route path="/odsjek/izvjestaji">
//             <InzvjestajOdsjek/>
//           </Route>
//           <Route path="/asu/izvjestaji">
  
//             <InzvjestajOdsjek2/>
//           </Route>
//           </>
//           : <Login />}
//             */}
//         </Switch>
//       </div>
//           </Switch>
//         </div>
//       </Router>
//     </UserProvider>
//   );
// }

// export default App;


