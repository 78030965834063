import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axios from 'axios';
import Url from '../api/defUrl';
import { useParams } from 'react-router-dom';

const EditPoll = () => {
    const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [editedAnketa, setEditedAnketa] = useState({});
  const [message, setMessage] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  const userId = localStorage.getItem('userId');
console.log(editedAnketa)
const options = [
    { value: "Redovno", label: "Redovno" },
    { value: "Ne baš redovno", label: "Ne baš redovno" },
    { value: "Neredovno", label: "Neredovno" },
    { value: "Nastave nije bilo", label: "Nastave nije bilo" }
  ];

  const options2 = [
    { value: "Nastava je izvođena po rasporedu, bez većih odstupanja", label: "Nastava je izvođena po rasporedu, bez većih odstupanja" },
    { value: "Nastava je izvođena po rasporedu, sa manjim odstupanjima", label: "Nastava je izvođena po rasporedu, sa manjim odstupanjima" },
    { value: "Nastava je izvođena sa velikim odstupanjima od rasporeda", label: "Nastava je izvođena sa velikim odstupanjima od rasporeda" },
    { value: "Nastava nije izvođena po rasporedu", label: "Nastava nije izvođena po rasporedu" }
  ];

  const options3 = [
    { value: "Vrlo zadovoljna/an", label: "Vrlo zadovoljna/an" },
    { value: "Zadovoljana/an", label: "Zadovoljana/an" },
    { value: "Djelomično zadovoljna/an", label: "Djelomično zadovoljna/an" },
    { value: "Nisam zadovoljna/an", label: "Nisam zadovoljna/an" }
  ];
  const options41 = [
    { value: "Vrlo zadovoljna/an", label: "Vrlo zadovoljna/an" },
    { value: "Zadovoljana/an", label: "Zadovoljana/an" },
    { value: "Djelomično zadovoljna/an", label: "Djelomično zadovoljna/an" },
    { value: "Nisam zadovoljna/an", label: "Nisam zadovoljna/an" }
  ];
  
  const options4 = [
    { value: "U potpunosti", label: "U potpunosti" },
    { value: "Uglavnom", label: "Uglavnom" },
    { value: "Ponekad", label: "Ponekad" },
    { value: "Nikada", label: "Nikada" }
  ];
  const options5 = [
    { value: "Potpuno su i stalno dostupni", label: "Potpuno su i stalno dostupni" },
    { value: "Uglavnom su na raspolaganju (uz manje nedostatke)", label: "Uglavnom su na raspolaganju (uz manje nedostatke)" },
    { value: "Postoje, ali su uglavnom nedostupni", label: "Postoje, ali su uglavnom nedostupni" },
    { value: "Nisu postojali i ne znam koji su", label: "Nisu postojali i ne znam koji su" }
  ];
  const options6 = [
    { value: "U dobroj mjeri opterećenja i obima", label: "U dobroj mjeri opterećenja i obima" },
    { value: "Mogao bi biti manje obiman", label: "Mogao bi biti manje obiman" },
    { value: "Mogao bi biti više obiman", label: "Mogao bi biti više obiman" },
    { value: "Mogao bi biti kompleksniji", label: "Mogao bi biti kompleksniji"},
    { value: "Mogao bi biti jednostavniji", label: "Mogao bi biti jednostavniji"},
    { value: "Previše je obiman i težak", label: "Previše je obiman i težak"},
  ];
  
  
  const options7 = [
    { value: "U potpunosti", label: "U potpunosti" },
    { value: "U većini slučajeva", label: "U većini slučajeva" },
    { value: "Uglavnom", label: "Uglavnom" },
    { value: "Ponekad", label: "Ponekad" },
    { value: "Nikada", label: "Nikada" }
  ];
  const options10 = [
    { value: "option1", label: "Više vježbi" },
    { value: "option2", label: "Više predavanja" },
    { value: "option3", label: "Više vremena za pripremanje praktičnih zadataka" },
    { value: "option4", label: "Nemam definisano osjećanje ili stav o ovom pitanju" },
  ];
  
  
  
  useEffect(() => {
    axios.get(`${Url}/api/anketa/${id}`)
      .then((response) => {
        setEditedAnketa(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedAnketa({
      ...editedAnketa,
      [name]: value,
    });
  };

  const handleInputChangeDouble = (event) => {
    const { name, value } = event.target;
    
    // Split the name into parts to access nested properties
    const [section, property] = name.split('.');
  
    setEditedAnketa((prevAnketa) => ({
      ...prevAnketa,
      [section]: {
        ...prevAnketa[section],
        [property]: value,
      },
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setEditedAnketa({
      ...editedAnketa,
      pitanje10: {
        ...editedAnketa.pitanje10,
        [name]: checked,
      },
    });
  };

  if (userId) {
    editedAnketa.izmjenio = userId;
  } 

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(editedAnketa);
    axios.put(`${Url}/api/anketa/${id}`, editedAnketa)
      .then((response) => {
        console.log("Anketa je uspješno ažurirana!", response.data);
        setMessage("Anketa je uspješno ažurirana!");
        setIsUpdated(true);
      })
      .catch((error) => {
        console.error("Došlo je do pogreške prilikom ažuriranja ankete:", error);
        setMessage("Došlo je do pogreške prilikom ažuriranja ankete.");
      });
  };

  return (
    <div style={{paddingLeft:15}}>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h1>Uredi anketu</h1>
          <Form.Group>
          <Form.Label className="fw-bold">1. Nastava na predmetu je izvođena:</Form.Label>
                {options.map((option) => (
                    <Form.Check
                    type="radio"
                    key={option.value}
                    label={option.label}
                    name="pitanje1"
                    value={option.value}
                    checked={editedAnketa.pitanje1 === option.value}
                    onChange={handleInputChange}
                    />
                ))}
                </Form.Group>
                <Form.Group>
                <Form.Label className="fw-bold">2. Da li je nastava izvođena u skladu sa rasporedom nastave?:</Form.Label>
                {options2.map((option) => (
                    <Form.Check
                    type="radio"
                    key={option.value}
                    label={option.label}
                    name="pitanje2" // Promijenjeno u "pitanje2"
                    value={option.value}
                    checked={editedAnketa.pitanje2 === option.value} // Promijenjeno u "editedAnketa.pitanje2"
                    onChange={handleInputChange} // Promijenjeno u "handleInputChange"
                    />
                ))}
                </Form.Group>
                <Form.Group>
        <Form.Label className="fw-bold">3. Kvalitetom izvođenja nastave i uzajamnom povezanošću predavanja i vježbi sam:</Form.Label>
        {options3.map((option) => (
            <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="pitanje3" // Promijenjeno u "pitanje3"
            value={option.value}
            checked={editedAnketa.pitanje3 === option.value} // Promijenjeno u "editedAnketa.pitanje3"
            onChange={handleInputChange} // Promijenjeno u "handleInputChange"
            />
        ))}
        </Form.Group>
        <Form.Group>
  <Form.Label className="fw-bold">4. Kvalitetom komunikacije i interakcije između profesora-ice/docenta-ice/višeg-e asistenta-ice/asistenta-ice i studenata sam:</Form.Label>
  {options3.map((option) => (
    <Form.Check
      type="radio"
      key={option.value}
      label={option.label}
      name="pitanje4.pitanje" // Update to "pitanje4.pitanje"
      value={option.value}
      checked={editedAnketa.pitanje4.pitanje === option.value}
      onChange={handleInputChangeDouble}
    />
  ))}
  <Form.Label>Molimo vas da ostavite dodatni komentar ili sugestiju:</Form.Label>
  <Form.Control
    as="textarea"
    rows={3}
    name="pitanje4.tekst" // Update to "pitanje4.tekst"
    placeholder="Unesite komentar"
    value={editedAnketa.pitanje4.tekst}
    onChange={handleInputChangeDouble}
  />
</Form.Group>

<Form.Group>
<Form.Label className="fw-bold">5. Da li sa profesorom-icom/docentom-icom/višim-om asistentom-icom/asistentom-icom možete ostvariti otvorenu komunikaciju, te iskazati svoj stav (čak i ako se razlikuje od stava nastavnika/saradnika)?</Form.Label>
  {options4.map((option) => (
    <Form.Check
      type="radio"
      key={option.value}
      label={option.label}
      name="pitanje5.pitanje" // Update to "pitanje4.pitanje"
      value={option.value}
      checked={editedAnketa.pitanje5.pitanje === option.value}
      onChange={handleInputChangeDouble}
    />
  ))}
  <Form.Label>Molimo vas da ostavite dodatni komentar ili sugestiju:</Form.Label>
  <Form.Control
    as="textarea"
    rows={3}
    name="pitanje5.tekst" // Update to "pitanje4.tekst"
    placeholder="Unesite komentar"
    value={editedAnketa.pitanje5.tekst}
    onChange={handleInputChangeDouble}
  />
</Form.Group>

<Form.Group>
<Form.Label className="fw-bold">6. Da li osjećate vlastiti napredak radeći sa profesorom-icom/docentom-icom/višim-om asistentom-icom/asistentom-icom?</Form.Label>
  {options4.map((option) => (
    <Form.Check
      type="radio"
      key={option.value}
      label={option.label}
      name="pitanje6.pitanje" // Update to "pitanje4.pitanje"
      value={option.value}
      checked={editedAnketa.pitanje6.pitanje === option.value}
      onChange={handleInputChangeDouble}
    />
  ))}
  <Form.Label>Molimo vas da ostavite dodatni komentar ili sugestiju:</Form.Label>
  <Form.Control
    as="textarea"
    rows={3}
    name="pitanje6.tekst" // Update to "pitanje4.tekst"
    placeholder="Unesite komentar"
    value={editedAnketa.pitanje6.tekst}
    onChange={handleInputChangeDouble}
  />
</Form.Group>

    <Form.Group>
    <Form.Label className="fw-bold">7. Da li je profesor-ica/docent-ica/viši-a asistent-ica/asistent-ica dostupan/na za konsultacije koje su utvrđene rasporedom? </Form.Label>
    {options4.map((option) => (
        <Form.Check
        type="radio"
        key={option.value}
        label={option.label}
        name="pitanje7" // Promijenjeno u "pitanje4"
        value={option.value}
        checked={editedAnketa.pitanje7 === option.value} // Promijenjeno u "editedAnketa.pitanje4"
        onChange={handleInputChange} // Promijenjeno u "handleInputChange"
        />
    ))}
    </Form.Group>
    <Form.Group>
    <Form.Label className="fw-bold">8. Sylabuss predmeta i neophodni udžbenici i sredstva za rad: </Form.Label>
    {options5.map((option) => (
        <Form.Check
        type="radio"
        key={option.value}
        label={option.label}
        name="pitanje8" // Promijenjeno u "pitanje4"
        value={option.value}
        checked={editedAnketa.pitanje8 === option.value} // Promijenjeno u "editedAnketa.pitanje4"
        onChange={handleInputChange} // Promijenjeno u "handleInputChange"
        />
    ))}
    </Form.Group>
    <Form.Group>
    <Form.Label className="fw-bold">9. Plan i program predmeta je:  </Form.Label>
    {options6.map((option) => (
        <Form.Check
        type="radio"
        key={option.value}
        label={option.label}
        name="pitanje9" // Promijenjeno u "pitanje4"
        value={option.value}
        checked={editedAnketa.pitanje9 === option.value} // Promijenjeno u "editedAnketa.pitanje4"
        onChange={handleInputChange} // Promijenjeno u "handleInputChange"
        />
    ))}
    </Form.Group>
    <Form.Group controlId="formCheckbox">
            <Form.Label className="fw-bold">10. Potrebno mi je: (možete označiti više od jednog odgovora, ako se nadopunju, tj. nisu u kontradikciji)</Form.Label>
            {options10.map((option) => (
              <Form.Check
                type="checkbox"
                key={option.value}
                label={option.label}
                name={option.value}
                checked={editedAnketa.pitanje10[option.value]}
                onChange={handleCheckboxChange}
              />
            ))}
          </Form.Group>
          <Form.Group>
    <Form.Label className="fw-bold">11. Plan i program predmeta je:  </Form.Label>
    {options7.map((option) => (
        <Form.Check
        type="radio"
        key={option.value}
        label={option.label}
        name="pitanje11" // Promijenjeno u "pitanje4"
        value={option.value}
        checked={editedAnketa.pitanje11 === option.value} // Promijenjeno u "editedAnketa.pitanje4"
        onChange={handleInputChange} // Promijenjeno u "handleInputChange"
        />
    ))}
    </Form.Group>
    <Form.Group>
    <Form.Label className="fw-bold">12. Da li razumijete indikacije i komentare koje dobivate od profesora'ice/docenta'ice/višeg'e
asistenta'ice/asistenta'ice, a odnose se na vaš rad?</Form.Label>
    {options7.map((option) => (
        <Form.Check
        type="radio"
        key={option.value}
        label={option.label}
        name="option12dodatno" // Promijenjeno u "pitanje4"
        value={option.value}
        checked={editedAnketa?.option12dodatno === option.value} // Promijenjeno u "editedAnketa.pitanje4"
        onChange={handleInputChange} // Promijenjeno u "handleInputChange"
        />
    ))}
    </Form.Group>

    <Form.Group>
    <Form.Label className="fw-bold">13. Da li vam je jasan i transparentan kriterij na osnovu kojeg profesor procjenjuje rad studenata?</Form.Label>
  {options7.map((option) => (
    <Form.Check
      type="radio"
      key={option.value}
      label={option.label}
      name="pitanje12.pitanje" // Update to "pitanje4.pitanje"
      value={option.value}
      checked={editedAnketa.pitanje12.pitanje === option.value}
      onChange={handleInputChangeDouble}
    />
  ))}
  <Form.Label>Molimo vas da ostavite dodatni komentar ili sugestiju:</Form.Label>
  <Form.Control
    as="textarea"
    rows={3}
    name="pitanje12.tekst" // Update to "pitanje4.tekst"
    placeholder="Unesite komentar"
    value={editedAnketa.pitanje12.tekst}
    onChange={handleInputChangeDouble}
  />
</Form.Group>  

    <Form.Group>
      <Form.Label className="fw-bold">14. Molimo vas da na kraju ostavite dodatni komentar ili sugestiju: (npr. prijedlog unaprijeñenja izvoñenja nastave)</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        name="pitanje13" // Promijenjeno u "message"
        placeholder="Enter message"
        value={editedAnketa.pitanje13}
        onChange={handleInputChange} // Promijenjeno u "handleInputChange"
      />
      {/* <div>
        <h5>Historija izmjena</h5>
        <p>{editedAnketa.izmjenio}</p>
      </div> */}
      </Form.Group>
            <Button onClick={handleSubmit} variant="primary" type="submit" style={{margin:20, float:"right", marginBottom:120 }}>
              Spremi promjene
            </Button>
    

          {isUpdated && (
            <Alert variant="success">
              Anketa je uspješno ažurirana!
            </Alert>
          )}
          {message && !isUpdated && (
            <Alert variant="danger">
              {message}
            </Alert>
          )}
        </>
      )}
    </div>
  );
};

export default EditPoll;
