import "../userList/userList.css";
// import { DataGrid } from "@material-ui/data-grid";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { userRows } from "../../dummyData";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import Url from '../api/defUrl'
export default function SviPredmeti() {
  const [data, setData] = useState([]);
  console.log(data)
    useEffect(() => {
      axios.get(`${Url}/api/predmeti/`)
        .then(res => {
          setData(res.data);
        })
        .catch(err => console.log(err));
    }, []);

  const handleDelete = (id) => {
    setData(data?.filter((item) => item.id !== id));
  };
  
  // /predmetd/:id/detalj/24
  const columns = [
    // { field: "_id", headerName: "ID", width: 90 },
    { field: "sifraPredmeta", headerName: "Šifra", width: 110 },
    { field: "nazivPredmeta", headerName: "Naziv predmeta", width: 300 },
    {
      field: "odsjek",
      headerName: "Odsjek",
      width: 200,
      renderCell: (params) => {
        const odsjek = params.row.odsjek
        
        return (
          <div className="userListUser">
            {odsjek.map((e)=>{
          return e.nazivOdsjeka
          })}
            {/* <p>{params.row.odsjek}</p> */}
            {/* <img className="userListImg" src={params.row.avatar} alt="" />
            {params.row.username} */}
          </div>
        );
      },
    },
    { field: "brojStudenata", headerName: "Broj studenata", width: 170 },
    {
      field: "nastavnik",
      headerName: "Nastavnik",
      width: 200,
      renderCell: (params) => {
        const nastavnik = params.row.nastavnik
        // console.log(params.row.nastavnik)
        return (

          <div className="userListUser">
            {/* <p>{params.row.nastavnik}</p> */}
            {nastavnik?.map((e)=>{
          return <>{e?.ime} {e?.prezime}</>
          })}
            {/* <p>{params.row.odsjek}</p> */}
            {/* <img className="userListImg" src={params.row.avatar} alt="" />
            {params.row.username} */}
          </div>
        );
      },
    },
    {
      field: "anketa",
      headerName: "Ankete",
      width: 200,
      renderCell: (params) => {
        // const odsjek = params.row.odsjek
        console.log(params?.row?.anketa)
        return (
          <div className="userListUser">
            <p>{params?.row?.anketa?.length}</p>
            {/* {odsjek.map((e)=>{
          return e.nazivOdsjeka
          })} */}
            {/* <p>{params.row.odsjek}</p> */}
            {/* <img className="userListImg" src={params.row.avatar} alt="" />
            {params.row.username} */}
          </div>
        );
      },
    },
    
    
    
    
    
    
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 120,
    // },
    // {
    //   field: "transaction",
    //   headerName: "Transaction Volume",
    //   width: 160,
    // },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Link to={"/user/" + params.row.id}>
    //           <button className="userListEdit">Edit</button>
    //         </Link>
    //         <DeleteOutline
    //           className="userListDelete"
    //           onClick={() => handleDelete(params.row.id)}
    //         />
    //       </>
    //     );
    //   },
    // },
  ];
  function getRowId(row) {
    return row?._id; // Replace with your unique id property
  }
  return (
    <div className="userList">
       <Link to={"/predmet-novi"}>
       <button className="productListEdit">Novi predmet</button>
       </Link>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={16}
        getRowId={getRowId}
        // getRowId={(row) => row._id}
        // checkboxSelection
      />
    </div>
  );
}
