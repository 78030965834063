// import "./newProduct.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {useState, useEffect} from 'react'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Typeahead } from 'react-bootstrap-typeahead'; 
import { Form, Button, Col, InputGroup } from "react-bootstrap";
import axios from 'axios';
import Url from './api/defUrl'
import { css } from '@emotion/react';
import { DotLoader } from 'react-spinners';


 function NoviKood() {

  const [koodId, setKoodId] = useState("");
  const [godinaStudija, setGodinaStudija] = useState("");
  const [brojKodova, setBrojKodova] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleKood = (e) => {
    setKoodId(e.target.value);
  };
  const handleBrojKodova = (e) => {
    setBrojKodova(e.target.value);
  };
  const handleGodinaStudija = (e) => {
    setGodinaStudija(e.target.value);
  };
  // console.log(multiSelections )
  const formData = {
    koodId,
    godinaStudija,
    brojKodova
  }

//  console.log(formData )
  //Cubmit axios form
  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${Url}/api/kood`, formData)
      .then(response => {
        alert("Uspesno ste kreirali kood")
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Form onSubmit={handleSubmit} className="asuFormular">
    <h1>Novi Kood</h1>
    {isLoading ? (
         <div className="loader-container" 
         style={{
           position: 'fixed',
           top: '50%',
           left: '50%',
           transform: 'translate(-50%, -50%)',
         }}>
         <DotLoader
           color={'#007bff'} // Boja spiner-a
           loading={isLoading}
           css={css`
             display: block;
             margin: 0 auto;
           `}
         />
       </div> // Prikazuje loader dok se podaci učitavaju
    ) : (
      // Prikazuje formular kada se podaci učitaju
      <>
     <Form.Group  className="mb-3 formaUnos">
    <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Unesi kood</InputGroup.Text>
        <Form.Control
          placeholder="Kood ID"
          aria-label="kood"
          aria-describedby="basic-addon1"
          onChange={handleKood}
        />
      </InputGroup>
    </Form.Group>
    <Form.Group  className="mb-3 formaUnos">
    <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Broj koodva za generisati</InputGroup.Text>
        <Form.Control
          placeholder="Broj kodova"
          aria-label="brojKodova"
          aria-describedby="basic-addon1"
          onChange={handleBrojKodova}
        />
      </InputGroup>
    </Form.Group>
    <Form.Group  className="mb-3 formaUnos">
    <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Godina studija</InputGroup.Text>
        <Form.Control
          placeholder=" Godina studija"
          aria-label="GodStudija"
          aria-describedby="basic-addon1"
          onChange={handleGodinaStudija}
        />
      </InputGroup>
    </Form.Group>
  <br/>
    <Button variant="primary" type="submit">
      Spasi
    </Button>
    </>
    )}
    <br/>
  </Form>

  );
}

export default NoviKood;