import { useState, useEffect, createContext, useContext } from "react";
import axios from 'axios';
import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "../home/home.css";
import Url from '../api/defUrl'
// import { userData } from "../../dummyData";
// import WidgetSm from "../../components/widgetSm/WidgetSm";
// import WidgetLg from "../../components/widgetLg/WidgetLg";
import { AnketaContext } from '../../components/context/ContextComponent';
import BirajNastavnikaIzvjestaj from "../../components/widgetSm/BirajNastavnikaIzvjestaj";
import TemplateOdgovora from "../../components/widgetSm/TemplateOdgovora";
// import BirajOdsjekIzvjestaj from "../../components/widgetSm/BirajOdsjekIzvjestaj";
// import TemplateOdgovoraUserDetalj from "../../components/widgetSm/TemplateOdgovoraUserDetalj";
import { css } from '@emotion/react';
import { DotLoader } from 'react-spinners';


export default function Izvjestaj() {
    // let { id } = useParams();

    const [anketeData, setAnketeData]               = useState([]);
    const [nastavniciData, setNastavniciData]       = useState([]);
    const [odsjeciData, setOdsjeciData]             = useState([]);
    const [predmetiData, setPredmetiData]           = useState([]);
    const [nastavnikAnketa, setNastavnikAnketa]     = useState("");
    const [predmetAnketa, setPredmetkAnketa]        = useState("");
    // const [odsjekPredmetAnketa, setOdsjekPredmetAnketa]        = useState("");
    const [odsjekPredmetIzvjestaj, setOdsjekPredmetIzvjestaj]        = useState("");
    const [odsjekPredmetAnkete, setOdsjekPredmetAnkete] = useState("")
    
    //component filter biraj odsjek
    const [odabraniOdsjekPredmet, setOdabraniOdsjekData] = useState([])
    const [loading, setLoading] = useState(true);
    // console.log(nastavnikAnketa)
    // console.log(odsjeciData)
    // console.log(anketeData)
    // console.log(anketeData)
    useEffect(() => {
        axios.get(`${Url}/api/anketa/`)
          .then(res => {
            setAnketeData(res.data);
            setLoading(false);
          })
        
          .catch(err => console.log(err));

        axios.get(`${Url}/api/odsjeci/`)
          .then(res => {
            setOdsjeciData(res.data);
            setLoading(false);
          })
          .catch(err => console.log(err));
    
          axios.get(`${Url}/api/predmeti/`)
          .then(res => {
            setPredmetiData(res.data);
            setLoading(false);
            // console.log(res.data)
          })
          axios.get(`${Url}/api/nastavnici/`)
          .then(res => {
            setNastavniciData(res.data);
            setLoading(false);
          })
          .catch(err => console.log(err));
      }, []);
    
    //   const pronadjiAnketuiNastavnika = () =>{
        
    //     let nasank = []
    //     console.log(nasank)
    //     nastavniciData.map((e)=>{
    //         if(e._id == nastavnikAnketa){
    //             nasank.push(e)
    //         }
    //     })

    //   }
    // const [selektovanNastavni, setSelektovanNastavni] = useState([])

        let nasank = []
		
      nastavniciData.map((e)=>{
          if(e._id == nastavnikAnketa){
              nasank.push(e)
            //   setSelektovanNastavni(e)
          }
      })
    //   console.log(nasank)
      let nastavnik_predmet = []
		//predmetAnketa je ID ankete
        nasank[0]?.predmet?.map((e)=>{
          if(e._id == predmetAnketa){
            nastavnik_predmet.push(e)
            //   setSelektovanNastavni(e)
          }
      })
    
      // if (loading) {
      //   return <p>Podaci se učitavaju...</p>;
      // }
// console.log(odabraniOdsjekPredmet)
  return (
    <div className="home">
 {loading ? (
         <div className="loader-container" 
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
         <DotLoader
           color={'#007bff'} // Boja spiner-a
           loading={loading}
           css={css`
             display: block;
             margin: 0 auto;
           `}
         />
       </div> // Prikazuje loader dok se podaci učitavaju
    ) : (
      // Prikazuje formular kada se podaci učitaju
      <>
<AnketaContext.Provider value={{odabraniOdsjekPredmet, odsjeciData, odsjekPredmetAnkete,odsjekPredmetIzvjestaj, anketeData, predmetiData, nastavniciData, setNastavnikAnketa, setPredmetkAnketa, setOdsjekPredmetIzvjestaj,setOdsjekPredmetAnkete, setOdabraniOdsjekData}}>
      {/* <FeaturedInfo /> */}
      {/* <Chart data={userData} title="User Analytics" grid dataKey="Active User"/> */}
      <div className="homeWidgets">
        {/* <WidgetSm/> */}
        {/* <WidgetLg/> */}
        <div className="widgetsm" style={{width:"100%"}}>
        {/* <BirajOdsjekIzvjestaj/> */}
        <BirajNastavnikaIzvjestaj/>
        <TemplateOdgovora data={anketeData} datab={nasank} datac={nastavnik_predmet} dataodsjek={odabraniOdsjekPredmet} datapredmet={predmetiData}/>
      </div>
      </div>
  </AnketaContext.Provider>
  </>
  )}
    </div>
  );
}
