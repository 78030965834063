import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Typography, Select, MenuItem, List, ListItem, ListItemText, Button } from '@mui/material';
import { Form, Col, InputGroup } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css'; // Uvoz CSS-a za typeahead
import Url from '../api/defUrl';

const NastavnikPredmetDetalj = () => {
  const { id } = useParams();
  const [nastavnik, setNastavnik] = useState(null);
  const [odabraniPredmet, setOdabraniPredmet] = useState("");
  const [sviPredmeti, setSviPredmeti] = useState([]);
  const [predmetis, setPredmetis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [multiSelections, setMultiSelections] = useState([]);

  useEffect(() => {
    const fetchNastavnik = async () => {
      try {
        const response = await axios.get(`${Url}/api/nastavnik/${id}`);
        setNastavnik(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchNastavnik();
  }, [id]);

  useEffect(() => {
    const fetchSviPredmeti = async () => {
      try {
        const response = await axios.get(`${Url}/api/predmeti`);
        // console.log(response.data);
         // Filtriranje predmeta prema semestru
         const filtriraniPredmeti = response.data.filter(
            (predmet) => predmet.semestar !== "zimski"
          );
        //   console.log(filtriraniPredmeti);
          setSviPredmeti(filtriraniPredmeti);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSviPredmeti();
  }, []);

//   useEffect(() => {
//     if (nastavnik) {
//       setPredmetis(nastavnik.predmet || []);
//     }
//   }, [nastavnik]);
useEffect(() => {
    if (nastavnik) {
      // Filtriraj predmete prema semestru
      const filtriraniPredmetiNastavnika = nastavnik.predmet.filter(
        (predmet) => predmet.semestar !== "zimski"
      );
      setPredmetis(filtriraniPredmetiNastavnika);
    }
  }, [nastavnik]);
  const handleDodajPredmet = async () => {
    // Logika za dodavanje odabranih predmeta

    // Ažurirajte nastavnika na serveru
    try {
      const noviPredmeti = sviPredmeti.filter((predmet) => multiSelections.includes(predmet._id));
      await axios.put(`${Url}/api/nastavnik/${id}`, {
        predmet: [...predmetis, ...noviPredmeti],
      });
      setPredmetis([...predmetis, ...noviPredmeti]);
      setMultiSelections([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleObrisiPredmet = async (predmetId) => {
    // Logika za brisanje predmeta

    // Filtrirajte predmete da biste uklonili onaj koji želite izbrisati
    const noviPredmeti = predmetis.filter((predmet) => predmet._id !== predmetId);
    setPredmetis(noviPredmeti);

    // Ažurirajte nastavnika na serveru
    try {
      await axios.put(`${Url}/api/nastavnik/${id}`, {
        predmet: noviPredmeti,
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Typography variant="h4">
        Prikaz nastavnika: {nastavnik.ime} {nastavnik.prezime}
      </Typography>

      <Form.Group style={{ marginTop: '20px' }} className="mb-3 formaUnos">
        <Form.Label className="fw-bold">Dodaj predmete</Form.Label>
        <Typeahead
          id="basic-typeahead-multiple"
          labelKey={(option) => `${option.sifraPredmeta} ${option.nazivPredmeta}`} // Prikazuje sifru i naziv predmeta
          multiple
          onChange={setMultiSelections}
          options={sviPredmeti} // Koristi ceo objekat predmeta za opcije
          placeholder="Odaberi..."
          selected={multiSelections}
        />
      </Form.Group>

      <List>
        {predmetis.map((predmet) => (
          <ListItem key={predmet._id}>
            <ListItemText primary={`${predmet.sifraPredmeta} ${predmet.nazivPredmeta} / ${predmet?.odsjek?.map((e) => e.nazivOdsjeka)}`} />
            <Button onClick={() => handleObrisiPredmet(predmet._id)}>
              Obriši
            </Button>
          </ListItem>
        ))}
      </List>

      <Button onClick={handleDodajPredmet}>Dodaj predmete</Button>
    </div>
  );
};

export default NastavnikPredmetDetalj;
