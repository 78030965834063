import { useState, createContext, useContext } from "react";
import { UserContext } from "./Context";
import { Form, Button, Col } from "react-bootstrap";

const BirajNastavnikaPredmet = (props) =>{
    const  {user, setOdsjekPredmet, setPredmetAnketa}  = useContext(UserContext);
    
    
    const handleSelectChange = (e)=>{
        const myArray = e.target.value.split(",");
        let a = []
        let b = []

        myArray.map((el)=>{
            let x = el.split(":")
            // console.log(x)
            if(x[0]=="predmetID"){
                a.push(x[1])
            } else if(x[0]=="Odsjek"){
                b.push(x[1])
            }else{
                b.push(x[0])
            }
        })
        setOdsjekPredmet(b)
        setPredmetAnketa(a)
        // console.log(e.target.value)
        // console.log("predmet",a)
        // console.log(b)
        // console.log(c)
    }
    const data = props.data
//    const datab = props.datab
        // console.log(user)
        // console.log(props.data)

        // returns a new object with the values at each key mapped using mapFn(value)
        const mappedObj = Object.keys(data).map((key) => {
            return {
              label: key,
              value: data[key]
            };
          });
          
        //   console.log(mappedObj);
        // console.log(datab)
    // }
    // let list = mappedObj?.map((e)=>{
    //     return <option value={e.value}>{e.label}</option>
    //     })


    //     const handlePred = (e) =>{
    //         console.log(e)
    //     }
       let list2 = data?.map((x)=>{
            return <option value={`predmetID:${x._id},Odsjek:${x?.odsjek?.map((y)=>y.nazivOdsjeka)}`}>{x.nazivPredmeta}</option>
        })

    
    return(
       
        <>
         <Form.Label className="fw-bold">Odaberi predmet:</Form.Label>
    <Form.Control as="select" 
    onChange={handleSelectChange}
    >
       <option value="none" selected disabled hidden> </option> 
       {list2}
    
    </Form.Control>
        </>
    )
}

export default BirajNastavnikaPredmet;