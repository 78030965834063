import { useState, useEffect, createContext, useContext } from "react";
import "./featuredInfo.css";
import { AnketaContext } from '../../components/context/ContextComponent';

import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

export default function FeaturedInfo() {
  // const {anketeData, predmetiData} = useContext(AnketaContext);
  // // console.log(predmetiData)
  // let col = ""
  // predmetiData.map((el)=>{
  //   col += el.brojStudenata
  // })
  // // console.log(col)
  // return (
  //   <div className="featured">
  //     <div className="featuredItem">
  //       <span className="featuredTitle">Broj anketa</span>
  //       <div className="featuredMoneyContainer">
  //         <span className="featuredMoney">{anketeData?.length}</span>
  //         {/* <span className="featuredMoneyRate">
  //           -11.4 <ArrowDownward  className="featuredIcon negative"/>
  //         </span> */}
  //       </div>
  //       {/* <span className="featuredSub">Compared to last month</span> */}
  //     </div>
  //     <div className="featuredItem">
  //       <span className="featuredTitle">Broj predmeta</span>
  //       <div className="featuredMoneyContainer">
  //         <span className="featuredMoney">{predmetiData?.length}</span>
  //         {/* <span className="featuredMoneyRate">
  //           -1.4 <ArrowDownward className="featuredIcon negative"/>
  //         </span> */}
  //       </div>
  //       {/* <span className="featuredSub">Compared to last month</span> */}
  //     </div>
  //     <div className="featuredItem">
  //       <span className="featuredTitle">Broj studenata</span>
  //       <div className="featuredMoneyContainer">
  //         <span className="featuredMoney">{col}</span>
  //         {/* <span className="featuredMoneyRate">
  //           +2.4 <ArrowUpward className="featuredIcon"/>
  //         </span> */}
  //       </div>
  //       {/* <span className="featuredSub">Compared to last month</span> */}
  //     </div>
  //   </div>
  // );

  return(
    <></>
  )
}
