import { useState, createContext, useContext } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { AnketaContext } from "../context/ContextComponent"
import Badge from 'react-bootstrap/Badge';

const PredmetOdsjekIzvjestaj = (data) =>{
    const  {odsjekPredmetIzvjestaj}  = useContext(AnketaContext);
    // console.log(odsjekPredmetIzvjestaj)
    return(
        <>
         {
           
           typeof(odsjekPredmetIzvjestaj) !=="string" ? 
           odsjekPredmetIzvjestaj && odsjekPredmetIzvjestaj?.map((e)=>{
            return  <>{e=="Odsjek za režiju" ? <Badge bg="success">{e}</Badge> 
            : e=="Odsjek za produkciju i menadžment u oblasti scenskih umjetnosti" ? <Badge bg="primary">{e}</Badge>
            : e=="Odsjek za dramaturgiju" ? <Badge bg="info">{e}</Badge>
            : e=="Odsjek za glumu" ? <Badge bg="warning">{e}</Badge>
            : e=="Odsjek za reziju (katedra AV)" ? <Badge bg="dark">{e}</Badge>
            : ""}</>
        })
            : ""
        }
        </>
    )
}

export default PredmetOdsjekIzvjestaj;