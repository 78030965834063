import React, { useState, useEffect, CSSProperties } from 'react';
import { useParams } from 'react-router-dom';
import Url from '../api/defUrl';
import axios from 'axios';
import { Typography, List, ListItem, ListItemText, Button, Alert } from '@mui/material';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ClipLoader from "react-spinners/ClipLoader";


// const override: CSSProperties = {
//     display: "block",
//     margin: "0 auto",
//     borderColor: "red",
//   };

const DetaljPredmeta24 = () => {
  const { id } = useParams();
  const [predmet, setPredmet] = useState(null);
  const [nastavnici, setNastavnici] = useState([]);
  const [odsjeci, setOdsjeci] = useState([]);
  const [odsjek, setOdsjek] = useState("");
const [kood, setKood] = useState([]);
const [selectedKood, setSelectedKood] = useState([]);
  const [selectedNastavnici, setSelectedNastavnici] = useState([]);
  const [selectedOdsjek, setSelectedOdsjek] = useState([]);
  const [selectedSemestar, setSelectedSemestar] = useState("");

  let [loading, setLoading] = useState(true);
//   let [color, setColor] = useState("#ffffff");
        // console.log(predmet) 
        console.log(selectedKood) 
  useEffect(() => {
    const fetchPredmet = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${Url}/api/predmeti/${id}`);
        setPredmet(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchPredmet();
  }, [id]);

  useEffect(() => {
    axios.get(`${Url}/api/odsjeci/`)
      .then(res => {
        setOdsjeci(res?.data);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    axios.get(`${Url}/api/kood/`)
      .then(res => {
        setKood(res?.data);
      })
      .catch(err => console.log(err));
  }, []);


  function filtrirajPoId(prviNiz, drugiNiz) {
    // Dobivanje niza _id-ova iz drugogNiza
    const drugiNizId = drugiNiz.map(item => item.code);
//   console.log("drugiNizId",drugiNizId)
    // Filtriranje prvogNiza prema _id-ovima iz drugogNiza
    const rezultat = prviNiz.filter(item => drugiNizId.includes(item._id));
  
    return rezultat;
  }
  

  useEffect(() => {
    // Postavi selectedNastavnici samo ako predmet.nastavnik postoji
    if (predmet && predmet.nastavnik) {
      setSelectedNastavnici(predmet.nastavnik);
    };

    if (predmet && predmet?.semestar) {
        setSelectedSemestar(predmet?.semestar);
      }
      if (predmet && predmet.kood) {
        const filtriraniNiz = filtrirajPoId(kood, predmet.kood);
        // console.log("filtriraniNiz",filtriraniNiz)
        setSelectedKood(filtriraniNiz);
      };
  }, [kood, predmet]);

  useEffect(() => {
    // Postavi selectedOdsjek samo ako predmet.odsjek postoji
    if (predmet && predmet.odsjek) {
      setSelectedOdsjek(predmet.odsjek);
    }
  }, [predmet])

//   console.log(predmet);
  useEffect(() => {
    const fetchNastavnici = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${Url}/api/nastavnici`);
        setNastavnici(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchNastavnici();
  }, []);

  const selectedNastavniciIds = selectedNastavnici?.map(nastavnik => nastavnik?._id);
  const selectedOdsjekIds = selectedOdsjek?.map(odsjek => odsjek?._id);
  const selectedKodIds = selectedKood?.map(kod => kod?._id);
// console.log(selectedKood)


  const handleEdit = async () => {
    // Logika za ažuriranje podataka o predmetu
    try {
        setLoading(true);
      const res = await axios.put(`${Url}/api/predmeti/${id}`, {
        selectedNastavnici: selectedNastavniciIds,
        selectedOdsjek: selectedOdsjekIds,
        selectedKood,
        selectedSemestar,
        predmet
        // Dodajte ostala polja koja želite ažurirati
      });
      if(res.status === 200){
        setLoading(false);
        alert("Uspjesno ste izmijenili podatke!")
        window.location.reload();
      }else{
        alert("Upssss, imamo problem")
        setLoading(false);
      }

    //   setPredmet(res.data)
     
      // Ažurirajte stanje komponente ili preusmerite korisnika
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    // Ažurirajte stanje predmeta kada korisnik mijenja input polje
    const { name, value } = e.target;
    setPredmet(prevPredmet => ({ ...prevPredmet, [name]: value }));
  };

//   useEffect(() => {
//     // Postavi početnu vrednost na osnovu postojećeg semestra iz baze
//     if (predmet && predmet?.odsjek) {
//       setSelectedSemestar(predmet?.odsjek);
//     }
//   }, [predmet]);

  const handleSemestarChange = (e) => {
    setSelectedSemestar(e.target.value);
  };

//   if (loading) {
//     return <p>Loading...</p>;
//   }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <ClipLoader color="#36D7B7" loading={loading} size={150} />
      </div>
    );
  }

  return (
    <div style={{ padding: "50px" }}>
         
         {/* <ClipLoader
        color={color}
        loading={loading}
        // cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> */}
      <Typography variant="h2">Detalji predmeta: {predmet?.nazivPredmeta}</Typography>
      {/* <p>Sifra predmeta: {predmet.sifraPredmeta}</p>
      <p>Broj studenata: {predmet.brojStudenata}</p>
      <p>Datum kreiranja: {predmet.createdAt}</p>
      <p>Semestar: {predmet.semestar}</p> */}
      <p>
        Sifra predmeta: 
   
          <input
            type="text"
            name="sifraPredmeta"
            value={predmet?.sifraPredmeta}
            onChange={handleChange}
          />
     
      </p>
      <p>
        Broj studenata: 
   
          <input
            type="text"
            name="brojStudenata"
            value={predmet?.brojStudenata}
            onChange={handleChange}
          />
      
      </p>
      {/* <p>Datum kreiranja: {predmet.createdAt}</p> */}
      <p>


          {/* <input
            type="text"
            name="semestar"
            value={predmet.semestar}
            onChange={handleChange}
          /> */}
        <label htmlFor="semestar">Odaberi semestar:</label>
      <select id="semestar" value={selectedSemestar} onChange={handleSemestarChange}>
        <option value="">Odaberi...</option>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((semestar) => (
          <option key={semestar} value={semestar}>
            {semestar}
          </option>
        ))}
      </select>

      {selectedSemestar && <p>Odabran semestar: {selectedSemestar}</p>}
      </p>
        <h3>Nastavnici:</h3>
        <Typeahead
        id="basic-typeahead-multiple"
        labelKey={(option) => `${option?.ime} ${option?.prezime}`}
        multiple
        onChange={(selected) => setSelectedNastavnici(selected)}
        options={nastavnici}
        placeholder="Odaberi..."
        selected={selectedNastavnici}
      />

        <h3>Odsjek:</h3>
        {/* <Form.Control
        type="text"
        value={selectedOdsjek}
        onChange={(e) => setSelectedOdsjek(e.target.value)}
        placeholder="Unesite odsjek"
        /> */}

        <Typeahead
        id="basic-typeahead-multiple"
        labelKey={(option) => `${option?.nazivOdsjeka}`}
        multiple
        onChange={(selected) => setSelectedOdsjek(selected)}
        options={odsjeci}
        placeholder="Odaberi..."
        selected={selectedOdsjek}
      />

        <h3>Kodovi:</h3>
        <Typeahead
        id="basic-typeahead-multiple"
        labelKey={(option) => `${option.koodId}`}
        multiple
        onChange={(selected) => setSelectedKood(selected)}
        options={kood}
        placeholder="Odaberi..."
        selected={selectedKood}
      />
      {/* Dodajte ostala polja koja želite editovati */}

      <Button variant="contained" style={{marginTop:20}} onClick={handleEdit}>
        Sačuvaj izjmene
      </Button>
    </div>
  );
};

export default DetaljPredmeta24;
