import React, { useState, useEffect, useContext } from 'react';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Typography, Select, MenuItem, List, ListItem, ListItemText } from '@mui/material';
import Url from '../api/defUrl';
import TemplateOdgovoraUserDetalj from '../../components/widgetSm/TemplateOdgovoraUserDetalj';
import { AnketaContext } from '../../components/context/ContextComponent';

const NastavnikDetalj = () => {
    const { id } = useParams();
    const [filtriraneAnkete, setFiltriraneAnkete] = useState([]);
    const [userID, setUserId] = useState(id)
    const [brojStudenata, setBrojStudenata] = useState(0);

  const [nastavnik, setNastavnik] = useState(null);
//   const [odsjeci, setOdsjeciData] = useState([]);
    const [odabraniPredmet, setOdabraniPredmet] = useState("");
  const [predmet, setPredmet] = useState({});
  const [listaPredmeta, setListaPredmeta] = useState([]);
  const [predmetis, setPredmetis] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchNastavnik = async () => {
      
        await axios.get(`${Url}/api/nastavnik/${id}`)
        .then(res => {
          setNastavnik(res.data);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
        
  
    };
    fetchNastavnik();
  }, [id]);


    useEffect(() => {
        if (nastavnik && userID) {
        let totalStudenata = 0;
    
        const filtriraneAnkete = nastavnik?.predmet?.flatMap((predmet) => {
            if (predmet?.anketa && predmet?.anketa.length > 0) {
            if (!odabraniPredmet || predmet._id === odabraniPredmet) {
                totalStudenata += predmet.brojStudenata;
                return predmet?.anketa.filter((anketa) => anketa?.nastvanik[0] === userID);
            }
            }
            return [];
        });
    
        setBrojStudenata(totalStudenata);
    
        // Dohvati listu svih predmeta za koje postoje filtrirane ankete
        const listaPredmeta = [...new Set(filtriraneAnkete?.map((anketa) => anketa?.predmet[0]))];
    
        setListaPredmeta(listaPredmeta);
        setFiltriraneAnkete(filtriraneAnkete);
        }
    }, [nastavnik, userID, odabraniPredmet]);
  
  
    if (loading) {
      return <p>Loading...</p>;
    }

//   const filtriraneAnkete = nastavnik?.predmet?.flatMap((predmet) =>
//   predmet?.anketa?.filter((anketa) => anketa?.nastvanik[0] === id)
// );


  
  return (
    <div>
     <Box>
     <Typography variant="h5">Odaberi predmet:</Typography>
<Select
  value={odabraniPredmet}
  style={{ minWidth: 500 }}
  onChange={(e) => {
    setOdabraniPredmet(e.target.value);
    if (e.target.value !== "") {
      const selectedPredmet = nastavnik?.predmet?.find(
        (predmet) => predmet._id === e.target.value
      );
      setPredmet(selectedPredmet);
    }
  }}
>
  <MenuItem  value="">Odaberite predmet</MenuItem>
  {nastavnik?.predmet?.map((predmet) => (
    <MenuItem key={predmet?._id} value={predmet?._id}>
      {predmet?.nazivPredmeta}
    </MenuItem>
  ))}
</Select>
     </Box>

{/* <TemplateOdgovora data={anketeData} datab={nasank} datac={nastavnik_predmet} dataodsjek={odabraniOdsjekPredmet} datapredmet={predmetiData}/> */}
    <TemplateOdgovoraUserDetalj nastavnikIme={nastavnik} nastavnik={filtriraneAnkete} id={id} brojStudenata={brojStudenata} predmet={predmet} svipredmeti={nastavnik.predmet}/>
    </div>
  );
};

export default NastavnikDetalj;
