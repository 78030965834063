// import "./newProduct.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useState} from 'react'
import Url from '../api/defUrl'
import { Form, Button, Col, InputGroup } from "react-bootstrap";
import axios from 'axios';



 function NewDepartment() {

  const [nazivOdsjeka, setNaziv] = useState("");
  const [prezime, setPrezime] = useState("");

  const [option12, setSelectedOption12] = useState("")


  const [selectedValue, setSelectedValue] = useState("");



  const handleNazivOdsjeka = (e) => {
    setNaziv(e.target.value);
  };
  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

console.log(nazivOdsjeka)
  // console.log(selectedOption11 )
  const formData = {
    nazivOdsjeka: nazivOdsjeka
  }
  
 console.log(formData )
  //Cubmit axios form
  const handleSubmit = (event) => {
    event.preventDefault();
  
    // console.log(formData)
   
    axios.post(`${Url}/api/odsjek`, formData)
      .then(response => {
        alert("Uspesno ste kreirali odsjek")
        console.log(response.data);
        // setFormData({
        //   pitanje1: '',
        //   pitanje2: '',
        //   pitanje3: '',
        //   pitanje4: '',
        //   pitanje5: '',
        //   pitanje6: '',
        //   pitanje7: '',
        //   pitanje8: '',
        //   pitanje9: '',
        //   pitanje10: '',
        //   pitanje11: '',
        // });
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Form onSubmit={handleSubmit} className="asuFormular">
    
    {/* <Form.Group  className="mb-3 formaUnos">
    <Form.Label className="fw-bold">Odaberi odsjek:</Form.Label>
    <Form.Control as="select" onChange={handleSelectChange}>
      <option value="">Odaberi odsjek</option>
      <option value="drama">Odsjek za Dramaturgiju</option>
      <option value="gluma">Odsjek za Glumu</option>
      <option value="produkcija">Odsjek za Produkciju</option>
      <option value="rezija">Odsjek za Reziju</option>
    </Form.Control>
    </Form.Group> */}

     <Form.Group  className="mb-3 formaUnos">
    <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Naziv Odsjeka</InputGroup.Text>
        <Form.Control
          placeholder="Naziv odsjeka"
          aria-label="Username"
          aria-describedby="basic-addon1"
          onChange={handleNazivOdsjeka}
        />
      </InputGroup>
    </Form.Group>
        {/* <Form.Group  className="mb-3">
        <Form.Label className="fw-bold">1. Nastava na predmetu je izvođena:</Form.Label>
        {options.map((option) => (
          <Form.Check
            type="text"
            key={option.value}
            label={option.label}
            name="option"
            value={option.value}
            checked={selectedOption === option.value}
            onChange={handleOptionChange}
          />
        ))}
      </Form.Group> */}

   
  <br/>
    <Button variant="primary" type="submit">
      Spasi
    </Button>
    <br/>
  </Form>

  );
}

export default NewDepartment;