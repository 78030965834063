// import "./newProduct.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {useState, Fragment} from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'; 
import { Form, Button, Col, InputGroup } from "react-bootstrap";
import Url from '../api/defUrl'
import axios from 'axios';

const testData =[
  {
    label: 'Alabama',
    population: 4780127,
    capital: 'Montgomery',
    region: 'South',
  },
  { label: 'Alaska', population: 710249, capital: 'Juneau', region: 'West' },
  { label: 'Arizona', population: 6392307, capital: 'Phoenix', region: 'West' },
  {
    label: 'Arkansas',
    population: 2915958,
    capital: 'Little Rock',
    region: 'South',
  },
]
 function NewNastavnik() {

  const [ime, setIme] = useState("");
  const [prezime, setPrezime] = useState("");
  const [multiSelections, setMultiSelections] = useState([]);
  const [titula, setTitula] = useState("");


  const formData = {
    ime: ime,
    prezime: prezime,
    titula: titula,
  }
  console.log(formData )
  const handleTitulaChange = (e) =>{
    setTitula(e.target.value);
  }

  const handleImeChange = (e) =>{
    setIme(e.target.value);
  }
  const handlePrezimeChange = (e) =>{
    setPrezime(e.target.value);
  }
//  console.log(formData )
  //Cubmit axios form
  const handleSubmit = (event) => {
    event.preventDefault();
  
    // console.log(formData)
   
    axios.post(`${Url}/api/nastavnik`, formData)
      .then(response => {
        console.log(response.data);
        alert("Uspesno ste kreirali korisnika")
        // window.location.replace("/nastavnik-novi/")
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Form onSubmit={handleSubmit} className="asuFormular">
     
     {/* <Form.Group style={{ marginTop: '20px' }} className="mb-3 formaUnos">
        <Form.Label>Multiple Selections</Form.Label>
        <Typeahead
          id="basic-typeahead-multiple"
          labelKey="label"
          multiple
          onChange={setMultiSelections}
          options={testData}
          placeholder="Odaberi..."
          selected={multiSelections}
        />
      </Form.Group> */}
       <Form.Group  className="mb-3 formaUnos">
    <Form.Label className="fw-bold">titula</Form.Label>
    <Form.Control as="select" onChange={handleTitulaChange}>
      <option value="">Odaberi titulu</option>
      <option value="rprof">red. prof</option>
      <option value="vprof">van. prof</option>
      <option value="doc">doc</option>
      <option value="vass">v. ass</option>
      <option value="ass">ass</option>
      <option value="sar">saradnik</option>
      {/* {
        odsjeciData.map((e)=>{
        return <option value={e._id}>{e.nazivOdsjeka}</option>
        })
      } */}
    </Form.Control>
    </Form.Group>
     <Form.Group  className="mb-3 formaUnos">
    <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Ime</InputGroup.Text>
        <Form.Control
          placeholder="Ime"
          aria-label="Username"
          aria-describedby="basic-addon1"
          onChange={handleImeChange}
        />
      </InputGroup>
    </Form.Group>
    <Form.Group  className="mb-3 formaUnos">
    <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Prezime</InputGroup.Text>
        <Form.Control
          placeholder="Prezime"
          aria-label="Username"
          aria-describedby="basic-addon1"
          onChange={handlePrezimeChange}
        />
      </InputGroup>
    </Form.Group>
  <br/>
    <Button variant="primary" type="submit">
      Spasi
    </Button>
    <br/>
  </Form>

  );
}

export default NewNastavnik;