import "./productList.css";
// import { DataGrid } from "@material-ui/data-grid";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useParams } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge'
import { DeleteOutline } from "@material-ui/icons";
import { productRows } from "../../dummyData";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import Url from '../api/defUrl'
import { dark } from "@material-ui/core/styles/createPalette";

export default function PollListUser() {
  const { id } = useParams();
  const [data, setData] = useState([]);
// console.log(data)

useEffect(() => {
  const fetchNastavnik = async () => {
    try {
      const response = await axios.get(`${Url}/api/nastavnik/ankete/${id}`);
      setData(response.data.anketa);
    } catch (error) {
      console.log('Greška prilikom dohvaćanja podataka o anketama:', error);
    }
  };
  fetchNastavnik();
}, [id]);

if (!data) {
  return <p>Učitavanje podataka...</p>;
}
// console.log("data", data)

  const columns = [
    // { field: "_", headerName: "ID", width: 60 },
    // {
    //   field: "nastavnik",
    //   headerName: "Nastavnik",
    //   width: 200,
    //   renderCell: (params) => {
    //     return (
    //       <div className="productListItem">
    //         {params.row.nastvanik.map((e)=> <p>{e.ime} {e.prezime}</p>)}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "predmet",
      headerName: "Predmet",
      width: 600,
      renderCell: (params) => {
        {console.log("params",params.row._id)}
        return (
          <div className="productListItem">
            {params.row.predmet.map((e)=> <p><Link to={`/anketa/${params.row._id}`}>{e.nazivPredmeta}</Link></p>)}
          </div>
        );
      },
    },
    {
      field: "pitanje1",
      headerName: "1",
      width: 52,
      renderCell: (params) => {
        // console.log(typeof(params.row.pitanje1))
        return (
          <div className="productListItem">
            {params.row.pitanje1 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje2",
      headerName: "2",
      width: 52,
      renderCell: (params) => {
        console.log(typeof(params.row.pitanje2))
        return (
          <div className="productListItem">
            {params.row.pitanje2 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje3",
      headerName: "3",
      width: 52,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.pitanje3 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje4",
      headerName: "4",
      width: 52,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params?.row?.pitanje4?.pitanje !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje5",
      headerName: "5",
      width: 52,
      renderCell: (params) => {
        // console.log(params.row.pitanje5.pitanje)
        return (
          <div className="productListItem">
            {params?.row?.pitanje5?.pitanje !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje6",
      headerName: "6",
      width: 52,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params?.row?.pitanje6?.pitanje !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje7",
      headerName: "7",
      width: 52,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params?.row?.pitanje7 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje8",
      headerName: "8",
      width: 52,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params?.row?.pitanje8 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje9",
      headerName: "9",
      width: 52,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params?.row?.pitanje9 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje10",
      headerName: "10",
      width: 52,
      renderCell: (params) => {
        const parametar = params?.row?.pitanje10;
        // console.log(parametar.option1 || parametar.option2 || parametar.option3 || parametar.option4)
        return (
          <div className="productListItem">
            {parametar?.option1 || parametar?.option2 || parametar?.option3 || parametar?.option4 !== false ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje11",
      headerName: "11",
      width: 52,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params?.row?.pitanje11 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje12",
      headerName: "12",
      width: 52,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params?.row?.pitanje12?.pitanje !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
    {
      field: "pitanje13",
      headerName: "13",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params?.row?.pitanje13 !== null ? <Badge bg="success">{"X"}</Badge> : <Badge bg="danger">{"0"}</Badge>}
          </div>
        );
      },
    },
  ];

  return (
    <div className="productList">
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        getRowId={(row) => row._id}
        pageSize={16}
        headerHeight={65}
        rowHeight={45}
        // checkboxSelection
      />
    </div>
  );
}
