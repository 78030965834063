// some mock context values
import {useEffect, createContext, useState} from 'react'
import axios from 'axios'


// const [nastavniciData, setNastavniciData] = useState([]);

// useEffect(() => {
//       axios.get('http://localhost:6200/api/nastavnici/')
//       .then(res => {
//         setNastavniciData(res.data);
//       })
//       .catch(err => console.log(err));
//   }, []);

  // create context
  export const UserContext = createContext(null);