import "./userList/userList.css";
// import { DataGrid } from "@material-ui/data-grid";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { DeleteOutline } from "@material-ui/icons";
// import { userRows } from "../../dummyData";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import Url from './api/defUrl'
import Button from '@mui/material/Button';

export default function Kodovi() {
  const [data, setData] = useState([]);
  console.log(data)
  useEffect(() => {
    axios.get(`${Url}/api/kood/`)
      .then(res => {
        // Filtriraj predmete prema semestru
        // const filtriraniPredmeti = res.data.filter(
        //   (predmet) => predmet.semestar !== "zimski"
        // );
        
        setData(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${Url}/api/kodovi/${id}`);
  
      if (response.status === 200) {
        alert(`Predmet sa ID ${id} je uspješno obrisan.`)
        // console.log(`Predmet sa ID ${id} je uspješno obrisan.`);
        // Osvježite podatke nakon brisanja ako je potrebno
        // setData(data?.filter((item) => item.id !== id));
      } else {
        alert(`Greška prilikom brisanja predmeta sa ID`)
        console.error(`Greška prilikom brisanja predmeta sa ID ${id}.`);
      }
    } catch (error) {
      console.error('Dogodila se pogreška prilikom izvršavanja delete metode:', error);
    }
  };
  
  const columns = [
    // { field: "_id", headerName: "ID", width: 90 },
    { field: "_id", headerName: "ID", width: 210 },
    {
        field: "koodId",
        headerName: "Naziv koda",
        width: 300,
        renderCell: (params) => {
          return (
            <div className="userListUser">
         
              <p>
                {params.row?.koodId}
                </p>
            </div>
          );
        },
      },
      { field: "godinaStudija", headerName: "Godina Studija", width: 210 },
    
      {
        field: "delete",
        headerName: "Brisanje",
        width: 100,
        renderCell: (params) => {
            // console.log(params?.row)
          return (
            <div className="userListUser">
              <Button
                variant="contained"
                color="warning"
                style={{height:"30px"}}
                onClick={() => handleDelete(params.row._id)}
                >
      Briši
    </Button>
            </div>
          );
        },
      },
    // {
    //   field: "transaction",
    //   headerName: "Transaction Volume",
    //   width: 160,
    // },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Link to={"/user/" + params.row.id}>
    //           <button className="userListEdit">Edit</button>
    //         </Link>
    //         <DeleteOutline
    //           className="userListDelete"
    //           onClick={() => handleDelete(params.row.id)}
    //         />
    //       </>
    //     );
    //   },
    // },
  ];
  function getRowId(row) {
    return row?._id; // Replace with your unique id property
  }
  return (
    <div className="userList">
       <Link to={"/kood/novi"}>
       <button className="productListEdit">Novi kôd</button>
       </Link>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={16}
        getRowId={getRowId}
        // getRowId={(row) => row._id}
        // checkboxSelection
      />
    </div>
  );
}
