import "../userList/userList.css";
// import { DataGrid } from "@material-ui/data-grid";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { userRows } from "../../dummyData";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Url from '../api/defUrl'
import axios from 'axios';

export default function OdsjeciAll() {

  const [data, setData] = useState([]);
  // console.log(data)
    useEffect(() => {
      axios.get(`${Url}/api/odsjeci/`)
        .then(res => {
          setData(res?.data);
        })
        .catch(err => console.log(err));
    }, []);
  
  
  // const handleDelete = (id) => {
  //   setData(data.filter((item) => item.id !== id));
  // };
  
  const columns = [
    // { field: "_id", headerName: "ID", width: 90 },
    { field: "nazivOdsjeka", headerName: "Naziv odsjeka", width: 500 },
    {
      field: "predmet",
      headerName: "Broj predmeta",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            <p>{params?.row?.predmet?.length}</p>
          </div>
        );
      },
    },
  ];
  function getRowId(row) {
    return row._id; // Replace with your unique id property
  }
  return (
    <div style={{ height: 400, width: '100%' }}>
       <Link to={"/odsjek-novi"}>
       <button className="productListEdit">Novi odsjek</button>
       </Link>
      <DataGrid
        rows={data}
        // disableSelectionOnClick
        columns={columns}
        pageSize={8}
        getRowId={getRowId}
        // getRowId={(row) => row._id}
        // getRowId={(row) => row._id}
        // checkboxSelection
      />
    </div>
  );
}
