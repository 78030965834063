// import "./newProduct.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect, createContext, useContext } from "react";
import { Form, Button, Col } from "react-bootstrap";
import axios from 'axios';
// import zIndex from '@mui/material/styles/zIndex';
// import BirajNastavnika from '../newPoll/birajNastavnika';
// import { UserContext } from '../newPoll/Context';
// import BirajNastavnikaPredmet from '../newPoll/birajNastavnikaPredmet';
// import BirajNastavnikaOdsjek from '../newPoll/birajOdsjekPredmet';
import Alert from "react-bootstrap/Alert";
import Url from '../api/defUrl'
import { css } from '@emotion/react';
import { DotLoader } from 'react-spinners';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
// import NastavnikPredmetBiranje from '../predmeti/NastavnikPredemetBiranje';

const options = [
  
//   { value: "Ne baš redovno", label: "Ne baš redovno" },
  { value: "Nastave nije bilo", label: "Nastave nije bilo" },
  { value: "Neredovno", label: "Nastava nije bila redovna" },
  { value: "Redovno", label: "Nastave je bila redovna" },
];

const options2 = [
    { value: "Nastava nije izvođena po rasporedu", label: "Nastava nije izvođena po rasporedu" },
    { value: "Nastava je izvođena sa velikim odstupanjima od rasporeda", label: "Nastava je izvođena po rasporedu, sa većim odstupanjima" },
  { value: "Nastava je izvođena po rasporedu, bez većih odstupanja", label: "Nastava je izvođena po rasporedu, bez većih odstupanja" },
];


const options3 = [
    { value: "Nisam zadovoljna/an", label: "Nisam zadovoljna/an" },
    { value: "Djelomično zadovoljna/an", label: "Djelomično zadovoljna/an" },
  { value: "Vrlo zadovoljna/an", label: "Vrlo zadovoljna/an" },
];

const options4 = [
    { value: "Nikada", label: "Nikada" },
    { value: "Uglavnom", label: "Uglavnom" },
    { value: "Ponekad", label: "Ponekad" },


];
const options5 = [
    { value: "Nisu postojali i ne znam koji su", label: "Nisu postojali i ne znam koji su" },
    { value: "Uglavnom su na raspolaganju (uz manje nedostatke)", label: "Uglavnom su na raspolaganju (uz manje nedostatke)" },
    { value: "Potpuno su i stalno dostupni", label: "Potpuno su i stalno dostupni" },
];
// const options6 = [
//   { value: "U dobroj mjeri opterećenja i obima", label: "U dobroj mjeri opterećenja i obima" },
//   { value: "Mogao bi biti manje obiman", label: "Mogao bi biti manje obiman" },
//   { value: "Mogao bi biti više obiman", label: "Mogao bi biti više obiman" },
//   { value: "Mogao bi biti kompleksniji", label: "Mogao bi biti kompleksniji"},
//   { value: "Mogao bi biti jednostavniji", label: "Mogao bi biti jednostavniji"},
//   { value: "Previše je obiman i težak", label: "Previše je obiman i težak"},
// ];


// const options7 = [
//   { value: "U potpunosti", label: "U potpunosti" },
//   { value: "U većini slučajeva", label: "U većini slučajeva" },
//   { value: "Uglavnom", label: "Uglavnom" },
//   { value: "Ponekad", label: "Ponekad" },
//   { value: "Nikada", label: "Nikada" }
// ];



 function AnketaStudent() {
    // const kood = localStorage.getItem('kood');
    const koodID = localStorage.getItem('koodID');
    // const kood = "GG124";
    // const koodID = "659ff933da066870c5e8eed1";

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedData, setIsSubmitteData] = useState("");
  // console.log(isSubmittedData)
  const [isLoading, setIsLoading] = useState(false);

//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [message1, setMessage1] = useState(null);
  const [message2, setMessage2] = useState(null);
  const [message3, setMessage3] = useState(null);
  const [message4, setMessage4] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  // const [selectedOption4, setSelectedOption4] = useState({
  //   option1: '',
  //   tekst: '',
  // });

  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOption6, setSelectedOption6] = useState(null);
  const [option7, setSelectedOption7] = useState(null);
  const [selectedOption8, setSelectedOption8] = useState(null);
  const [selectedOption9, setSelectedOption9] = useState(null);
  const [selectedOption10, setSelectedOption10] = useState(null);
  const [selectedOption11, setSelectedOption11] = useState(null)
  const [option12, setSelectedOption12] = useState(null)
  const [option12dodatno, setSelectedOption12dodatno] = useState(null)
  const [selectedOption121, setSelectedOption121] = useState(null)
  
  const userId = localStorage.getItem('userId');
  // console.log("userId",userId)
  const [optionsc, setOptionsc] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  });
//   const handleCheckboxChange = (event) => {
//     const { name, checked } = event.target;
//     setOptionsc({ ...optionsc, [name]: checked });
//   };
  
  const [nastavniciData, setNastavniciData] = useState([]);
  const [odsjeciData, setOdsjeciData] = useState([]);
  const [predmetiData, setPredmetiData] = useState([]);

 const getNastavniciForPredmeti = () => {
    const nastavniciIDs = predmetiData.flatMap(predmet => predmet.nastavnik.map(nastavnik => nastavnik._id));
    return nastavniciData.filter(nastavnik => nastavniciIDs.includes(nastavnik._id));
  };

  const nastavniciForPredmeti = getNastavniciForPredmeti();
//   console.log(nastavniciForPredmeti)
// };
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleOptionChange2 = (e) => {
    setSelectedOption2(e.target.value);
  };
  const handleOptionChange3 = (e) => {
    setSelectedOption3(e.target.value);
  };
  const handleOptionChange4 = (e) => {
    setSelectedOption4(e.target.value);
  };
  const handleOptionChange6 = (e) => {
    setSelectedOption6(e.target.value);
  };
  const handleOptionChange7 = (e) => {
    setSelectedOption7(e.target.value);
  };
  const handleOptionChange8 = (e) => {
    setSelectedOption8(e.target.value);
  };
  const handleOptionChange9 = (e) => {
    setSelectedOption9(e.target.value);
  };
  const handleOptionChange10 = (e) => {
    setSelectedOption10(e.target.value);
  };
  const handleOptionChange11 = (e) => {
    setSelectedOption11(e.target.value);
  };
  const handleOptionChange12 = (e) => {
    setSelectedOption12(e.target.value);
  };
  const handleOptionChange121 = (e) => {
    setSelectedOption121(e.target.value);
  };
  const handleOptionChange12dodatno = (e) => {
    setSelectedOption12dodatno(e.target.value);
  };


 
  useEffect(() => {
    setIsLoading(true); 
    axios.get(`${Url}/api/odsjeci/`)
      .then(res => {
        setOdsjeciData(res.data);
      })
      .catch(err => console.log(err));

      axios.get(`${Url}/api/predmeti/kood/${koodID}`)
      .then(res => {
        setPredmetiData(res.data);
        // console.log(res.data)
      })
      axios.get(`${Url}/api/nastavnici/`)
      .then(res => {
        setNastavniciData(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => {
        setIsLoading(false); // Postavite isLoading na false nakon što se podaci učitaju
      });
  }, []);


  const duploPitanje4 = {
    "pitanje": selectedOption4,
    "text": message
  }

  const [selectedNastavnik, setSelectedNastavnik] = useState("");
  const [selectedPredmet, setSelectedPredmet] = useState("");
  // console.log(selectedOption11 )
  
//   console.log("selectedPredmet",selectedPredmet.length = 0)
  const handleNastavnikChange = (e) => {
    const selectedNastavnikID = e.target.value;
    setSelectedNastavnik(selectedNastavnikID);
    setSelectedPredmet(""); // Resetiraj odabrani predmet kada se promeni nastavnik
  };

  const handlePredmetChange = (e) => {
    const selectedPredmetID = e.target.value;
    setSelectedPredmet(selectedPredmetID);
  };

  const getPredmetiForNastavnik = () => {
    if (!selectedNastavnik) {
      return [];
    }
  
    const nastavnikData = nastavniciForPredmeti.find(
      (nastavnik) => nastavnik._id === selectedNastavnik
    );
    // console.log("nastavnikData",nastavnikData)
    if (nastavnikData && nastavnikData.predmet) {
        // console.log(nastavnikData)
      return nastavnikData.predmet
        .filter(
          (predmet) =>
            predmet.kood &&
            predmet.kood.some(
                // (k) => k.code === "GG124"
            //    (k)=> console.log("k",k.nastavnik)
              (k) => k.code === koodID && k.nastavnik && k.nastavnik === selectedNastavnik
            )
        )
        .map((filteredPredmet) => ({
          _id: filteredPredmet._id,
          nazivPredmeta: filteredPredmet.nazivPredmeta,
          nastavnik:nastavnikData._id
        }));
    } else {
      return [];
    }
  };

  const predmetiForNastavnik = getPredmetiForNastavnik();
//   console.log("predmetiForNastavnik",predmetiForNastavnik)
//   console.log(nastavniciForPredmeti)

// Funkcija koja filtrira nastavnike i predmete prema koodID
// function filtrirajNastavnike() {
//     const filtriraniNastavnici = nastavniciForPredmeti.filter((nastavnik) => {
//       // Provjera svakog predmeta za trenutnog nastavnika
//     //   console.log("nastavnik",nastavnik)
//       nastavnik.predmet = nastavnik?.predmet?.filter((predmet) => {
//         // Provjera koodID-a u nizu kood za svaki predmet
//         return predmet?.kood.some((kod) => kod.code !== koodID);
//       });
  
//       // Ako ima predmeta nakon filtriranja, zadrži tog nastavnika
//       return nastavnik?.predmet?.length > 0;
//     });
  
//     return filtriraniNastavnici;
//   }
  
//   // Dobivanje filtriranih nastavnika
//   const filtriraniNastavnici = filtrirajNastavnike();
  
//   // Ispis filtriranih nastavnika
//   console.log("filtriraniNastavnici", filtriraniNastavnici);



  const formData = {
    koodID,
    kreirao: userId,
    nastvanik: selectedNastavnik,
    predmet: selectedPredmet,
    pitanje1: selectedOption,
    pitanje2: selectedOption2,
    pitanje3: selectedOption3,
    pitanje4: selectedOption4, //komentar + message
    pitanje5: selectedOption6,//komentar + message,
    pitanje6: option7,//komentar + message,,
    pitanje7: selectedOption8,
    pitanje8: selectedOption9,
    pitanje9: selectedOption10,
    pitanje10: option12dodatno,
    // pitanje11: selectedOption12,
    // option12dodatno: option12dodatno,
    pitanje12: option12,
    pitanje13: message4,
  }

//  console.log(formData )
  //Cubmit axios form
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true); 
    // setIsSubmitted(true);
    // console.log(formData)
   
    axios.post(`${Url}/api/anketa`, formData)
      .then(response => {

        // console.log(response.data); 
        setIsSubmitted(true);
        setIsSubmitteData(response.data);
        setIsLoading(false);  
        alert("Vaš odgovorr je zabilježen!")
         window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };


  //ne zelim
  const handleReset = async (event) => {
    try {
      // event.preventDefault();
  
      // setIsSubmitted(true);
      // console.log(formData)
      const nezelimData = {
        nezelim: true,
        // kood,
        koodID,
        kreirao: userId,
        nastvanik: selectedNastavnik,
        predmet: selectedPredmet,
        pitanje1: "",
        pitanje2: "",
        pitanje3: "",
        pitanje4: "", //komentar + message
        pitanje5: "",//komentar + message,
        pitanje6: "",//komentar + message,,
        pitanje7: "",
        pitanje8: "",
        pitanje9: "",
        pitanje10: "",
        pitanje12: "",
        pitanje13: "",
      };
  
      setIsLoading(true);
      
      // Dodajemo "await" ispred axios poziva kako bismo čekali na rezultat asinkronog poziva
      const res = await axios.post(`${Url}/api/anketa/nezelim`, nezelimData);
      
      console.log(res.data); // Proučite podatke iz odgovora
        if(res.data.status === 200){
            setIsLoading(false);
    
            alert("Vaš odgovor je zabilježen!");
            window.location.reload();
        }else{
            setIsLoading(false);
            alert("Vaš odgovor nije zabilježen pokušajte ponovo!");
            window.location.reload();
        }
      // window.location.reload();
    } catch (error) {
        alert("Došlo je do greške prilikom slanja odgovora:!");
      console.error("Došlo je do greške prilikom slanja odgovora:", error.message);
      setIsLoading(false);
      // Ovdje možete dodati dodatne radnje ili prikazati poruku o grešci korisnicima
    }
  };
  

  
  // console.log("Parent:",odsjekPredmet)


  return (
    <Form onSubmit={handleSubmit} className="asuFormular">
     {
  isSubmittedData ? (
    <Alert variant="success" style={{ width: "42rem" }}>
      <Alert.Heading>Podaci su spaseni u bazi! ID ankete: {isSubmittedData._id}</Alert.Heading>
    </Alert>
  ) : (
    isSubmitted ? (
      <Alert variant="danger" style={{ width: "42rem" }}>
        <Alert.Heading>Kreska prilikom kreiranja ankete</Alert.Heading>
      </Alert>
    ) : null // Null se koristi ako ne želite prikazati poruku ako nije isSubmitted ni isSubmittedData
  )
}
     <h1>ANKETE 2024</h1>
     <h3>Univerzitet u Sarajevu - Akademija scenskih umjetnosti</h3>
     {/* <h4>Broj preostalih anketa: {predmetiForNastavnik?.length}</h4> */}
     {isLoading ? (
         <div className="loader-container" 
         style={{
           position: 'fixed',
           top: '50%',
           left: '50%',
           transform: 'translate(-50%, -50%)',
         }}>
         <DotLoader
           color={'#007bff'} // Boja spiner-a
           loading={isLoading}
           css={css`
             display: block;
             margin: 0 auto;
           `}
         />
         
       </div> // Prikazuje loader dok se podaci učitavaju
       
    ) : (
      <>
      <Form.Group  className="mb-3">
        </Form.Group>
        <Form.Group  className="mb-3 formaUnos">

    </Form.Group>

<div >
    <FormControl style={{width:"100%"}}>
      <InputLabel htmlFor="nastavnik">Odaberi nastavnika:</InputLabel>
      <Select
        id="nastavnik"
        onChange={handleNastavnikChange}
        value={selectedNastavnik}
        label="Odaberi nastavnika"
      >
        <MenuItem value="" >
          <em>-- Odaberi nastavnika --</em>
        </MenuItem>
        {nastavniciForPredmeti.map((nastavnik) => (
          <MenuItem key={nastavnik._id} value={nastavnik._id}>
            {nastavnik.ime} {nastavnik.prezime}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
   <br/>
   <br/>
    {selectedNastavnik && (
      <div>
        <FormControl style={{width:"100%"}}>
          <InputLabel htmlFor="predmet">Odaberi predmet:</InputLabel>
          <Select
            id="predmet"
            onChange={handlePredmetChange}
            value={selectedPredmet}
            label="Odaberi predmet"
          >
            <MenuItem value="">
              <em>-- Odaberi predmet --</em>
            </MenuItem>
            {predmetiForNastavnik.map((predmet) => (
              <MenuItem key={predmet._id} value={predmet._id}>
                {predmet.nazivPredmeta}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )}
  </div>


      {/* <NastavnikPredmetBiranje nastavniciForPredmeti={nastavniciForPredmeti}/> */}
        <Form.Group  className="mb-3">
        <Form.Label className="fw-bold">1. Nastava na predmetu je izvođena:</Form.Label>
        {options.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option"
            value={option.value}
            checked={selectedOption === option.value}
            onChange={handleOptionChange}
          />
        ))}
      </Form.Group>

      <Form.Group>
      <Form.Label className="fw-bold">2. Da li je nastava izvođena u skladu sa rasporedom nastave?:</Form.Label>
        {options2.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option2"
            value={option.value}
            checked={selectedOption2 === option.value}
            onChange={handleOptionChange2}
          />
        ))}
      </Form.Group>

      <Form.Group>
      <Form.Label className="fw-bold">3. Kvalitetom izvođenja nastave i uzajamnom povezanošću predavanja i vježbi sam:</Form.Label>
        {options3.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option3"
            value={option.value}
            checked={selectedOption3 === option.value}
            onChange={handleOptionChange3}
          />
        ))}
      </Form.Group>
        <Form.Group>
      <Form.Label className="fw-bold">4. Kvalitetom komunikacije i interakcije između profesora-ice/docenta-ice/višeg-e asistenta-ice/asistenta-ice i studenata sam:</Form.Label>
        {options3.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option4"
            value={option.value}
            checked={selectedOption4 === option.value}
            onChange={handleOptionChange4}
          />
        ))}
        {/* <Form.Label>Molimo vas da ostavite dodatni komentar ili sugestiju:</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        placeholder="Enter message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      /> */}
      </Form.Group>


      <Form.Group>
      <Form.Label className="fw-bold">5. Da li sa profesorom-icom/docentom-icom/višim-om asistentom-icom/asistentom-icom možete ostvariti otvorenu komunikaciju, te iskazati svoj stav (čak i ako se razlikuje od stava nastavnika/saradnika)?</Form.Label>
        {options4.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option6"
            value={option.value}
            checked={selectedOption6 === option.value}
            onChange={handleOptionChange6}
          />
        ))}
        {/* <Form.Label>Molimo vas da ostavite dodatni komentar ili sugestiju:</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        placeholder="Enter message"
        value={message1}
        onChange={(e) => setMessage1(e.target.value)}
      /> */}
      </Form.Group>

      <Form.Group>
      <Form.Label className="fw-bold">6. Da li osjećate vlastiti napredak radeći sa profesorom-icom/docentom-icom/višim-om asistentom-icom/asistentom-icom?</Form.Label>
        {options4.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option7"
            value={option.value}
            checked={option7 === option.value}
            onChange={handleOptionChange7}
          />
        ))}
        {/* <Form.Label>Molimo vas da ostavite dodatni komentar ili sugestiju:</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        placeholder="Enter message"
        value={message2}
        onChange={(e) => setMessage2(e.target.value)}
      /> */}
      </Form.Group>
      
<Form.Group>
      <Form.Label className="fw-bold">7. Da li je profesor-ica/docent-ica/viši-a asistent-ica/asistent-ica dostupan/na za konsultacije koje su utvrđene rasporedom? </Form.Label>
        {options4.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option8"
            value={option.value}
            checked={selectedOption8 === option.value}
            onChange={handleOptionChange8}
          />
        ))}
      </Form.Group>
      
      <Form.Group>
      <Form.Label className="fw-bold">8. Sylabuss predmeta i neophodni udžbenici i sredstva za rad: </Form.Label>
        {options5.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option9"
            value={option.value}
            checked={selectedOption9 === option.value}
            onChange={handleOptionChange9}
          />
        ))}
      </Form.Group>

      {/* <Form.Group>
      <Form.Label className="fw-bold">9. Plan i program predmeta je:  </Form.Label>
        {options6.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option10"
            value={option.value}
            checked={selectedOption10 === option.value}
            onChange={handleOptionChange10}
          />
        ))}
      </Form.Group>

      <Form.Group controlId="formCheckbox">
      <Form.Label className="fw-bold">10. Potrebno mi je: (možete označiti više od jednog odgovora, ako se nadopunju, tj. nisu u kontradikciji)  </Form.Label>
      <Form.Check
          type="checkbox"
          label="Više vježbi"
          name="option1"
          checked={options.option1}
          onChange={handleCheckboxChange}
        />
        <Form.Check
          type="checkbox"
          label="Više predavanja"
          name="option2"
          checked={options.option2}
          onChange={handleCheckboxChange}
        />
        <Form.Check
          type="checkbox"
          label="Više vremena za pripremanje praktičnih zadataka"
          name="option3"
          checked={options.option3}
          onChange={handleCheckboxChange}
        />
        <Form.Check
          type="checkbox"
          label="Nemam definisano osjećanje ili stav o ovom pitanju"
          name="option4"
          checked={options.option4}
          onChange={handleCheckboxChange}
        />
      </Form.Group> */}

      <Form.Group>
      <Form.Label className="fw-bold">9. Da li su vam bili jasno predstavljeni vaši zadaci i obaveze u procesu nastave?</Form.Label>
        {options4.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option10"
            value={option.value}
            checked={selectedOption10 === option.value}
            onChange={handleOptionChange10}
          />
        ))}
      </Form.Group>
      
<Form.Group>
      <Form.Label className="fw-bold">10. Da li razumijete povratne informacije koje dobivate od profesora-ice/docenta-ice/višeg-e asistenta-ice/asistenta-ice, a odnose se na vaš rad?</Form.Label>
        {options4.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option12dodatno"
            value={option.value}
            checked={option12dodatno === option.value}
            onChange={handleOptionChange12dodatno}
          />
        ))}
      </Form.Group>
      <Form.Group>
      <Form.Label className="fw-bold">11. Da li vam je jasan i transparentan kriterij na osnovu kojeg profesor-ica/docent-ica/viši-a asistent-ica/asistent-ica procjenjuju rad studenata?</Form.Label>
        {options4.map((option) => (
          <Form.Check
            type="radio"
            key={option.value}
            label={option.label}
            name="option12"
            value={option.value}
            checked={option12 === option.value}
            onChange={handleOptionChange12}
          />
        ))}
      {/* <Form.Label>Molimo vas da ostavite dodatni komentar ili sugestiju:</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        placeholder="Enter message"
        value={message3}
        onChange={(e) => setMessage3(e.target.value)}
      /> */}
      </Form.Group>

      <Form.Group>
      <Form.Label className="fw-bold">12. Molimo vas da na kraju ostavite dodatni komentar ili sugestiju: (npr. prijedlog unaprijedjenja izvodjenja nastave)</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        placeholder="Enter message"
        value={message4}
        onChange={(e) => setMessage4(e.target.value)}
      />
      </Form.Group>
  <br/>

  {selectedPredmet.length !== 0 ? <Button variant="primary" type="submit" style={{width:"40%"}}>Spasi</Button>:""}
  {selectedPredmet.length !== 0 ? <Button style={{float:"right", width:"40%"}} variant="danger" onClick={handleReset}>Ne zelim popuniti</Button> :""}
    </>
        )}
    <br/>
  </Form>

  );
}

export default AnketaStudent;